// import React, { useEffect, useState } from 'react';
// import PropertyFilter from '../Filterpages/FilterComponent';
// import Filterpageproperty from '../Filterpages/FilterpageView';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { API_BASE_URL } from '../../utils';
// import { useAuthHeaders } from '../Tokenrefresh';
// import { Col, Row } from 'react-bootstrap';

// function Appartment({ categoryId }) {


//   const [properties, setProperties] = useState([]);




//   // Fetch properties for the selected category
//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/property/propertiesbysubcategory?categoryId=${categoryId}`)
//       .then((response) => {
//         setProperties(response.data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching properties data:", error);
//       });
//   }, [categoryId]);

//   return (
//     <div className='container'>
//       <Row>
//         <Col md={12} className='filter-col'>
//           <Filterpageproperty />
//         </Col>
//       </Row>
//       <Row>
//         <Col md={12} className='filter-col'>
//           <nav>
//             <ol className="breadcrumb w-50 p-2 mt-2">
//               <li className="breadcrumb-item">
//                 <Link to="/" className='text-decoration-none text-black'>
//                   Chennai Auction Property
//                 </Link>
//               </li>
//               <li className="breadcrumb-item">
//                 <Link to={`/apartment/${categoryId}`} className='text-decoration-none text-black'>
//                   Appartment
//                 </Link>
//               </li>
//             </ol>
//           </nav>
//         </Col>
//       </Row>
//       <div className='row'>
//         <div className='col-md-8'>
//           <h4 className='bg-white w-50 p-4 sha border border-0'>
//             Flats for sale in Chennai
//           </h4>
//           <Row className="ms-5">
//             {console.log(properties)}
//             {properties.map((category) => (
//               <Col xs={12} sm={6} md={4} lg={2} key={category._id} className="mb-4">
//                 <div className="contain card ms-2 d-flex justify-content-center">
//                   <img
//                     // src}
//                     className="find-1"
//                     alt="property"
//                   />

//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </div>
//         <div className='col'>
//           <PropertyFilter />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Appartment;
// import React, { useEffect, useState, useRef } from 'react';
// import PropertyFilter from '../Filterpages/FilterComponent';
// import Filterpageproperty from '../Filterpages/FilterpageView';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { API_BASE_URL } from '../../utils';
// import { Col, Row } from 'react-bootstrap';
// import EnquiryFormModal from '../Enquiry/Enquiry';
// import "./Sub.css";

// function Appartment({ categoryId }) {
//   const [data, setProperties] = useState([]);
//   const [expandedDescriptions, setExpandedDescriptions] = useState({});
//   const [modalShow, setModalShow] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [propertiesPerPage] = useState(6); // Adjust as needed
//   const scrollRef = useRef(null);

//   useEffect(() => {
//     axios.get(`${API_BASE_URL}/property/getproperty`, {
//       params: {
//         propertyType: 'Appartments'
//       }
//     })
//       .then((response) => {
//         setProperties(response.data.data.properties);
//       })
//       .catch((error) => {
//         console.error('Error fetching properties:', error);
//       });
//   }, [categoryId]);

//   useEffect(() => {
//     // Scroll a bit higher than the top of the component when it mounts
//     if (scrollRef.current) {
//       scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
//       window.scrollBy(0, -800); // Scroll up by 100 pixels
//     }
//   }, []);

//   const toggleDescription = (index) => {
//     setExpandedDescriptions(prevState => ({
//       ...prevState,
//       [index]: !prevState[index]
//     }));
//   };

//   const formatPrice = (price) => {
//     if (price >= 10000000) {
//       return (price / 10000000).toFixed(2) + ' Cr';
//     } else if (price >= 100000) {
//       return (price / 100000).toFixed(2) + ' L';
//     } else {
//       return price.toString();
//     }
//   };

//   // Pagination logic
//   const indexOfLastProperty = currentPage * propertiesPerPage;
//   const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
//   const currentProperties = data.slice(indexOfFirstProperty, indexOfLastProperty);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);
//   const nextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(data.length / propertiesPerPage)));
//   const prevPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));


//   return (
//     <div className='container' ref={scrollRef}>
//       <Row>
//         <Col md={12} className='filter-col'>
//           <Filterpageproperty />
//         </Col>
//       </Row>
//       <Row>
//         <Col>
//           <nav className='ms-3'>
//             <ol className="breadcrumb  ">
//               <li className="breadcrumb-item "><Link to={"/"} className='text-decoration-none text-black'>Chennai Auction Property</Link></li>
//               <li className="breadcrumb-item active">Appartments</li>
//             </ol>
//           </nav>
//         </Col>
//       </Row>
//       <div className='row'>
//         <div className='col-md-8'>
//         <div className="scrollable-container">
//     {currentProperties.map((property, index) => (
//     <div className="card-container1 mt-5 mb-5" key={index}>
      
//       <div className="card-header1">
//         <img src={property.propertyImages[0].propertyImage} alt="card-image" className="card-image1" />
//       </div>
      
//       <div className="card-body1 m-2">
//         <h4 className="card-subtitle1">{property.propertyTitle}</h4>
//         <h6 className="card-title1">
//           <span className=' text-capitalize ' style={{fontSize: "12px", fontWeight:"200px"}}><i className="fa-solid fa-location-dot"></i> {property.address}</span>
//         </h6>
//         <table className="table table-striped-columns border border-2">
//           <thead>
//             <tr className='bg-color-2'>
//               <th className=' text-center fw-medium'>Type</th>
//               <th className=' text-center fw-medium'>Property Type</th>
//               <th className=' text-center fw-medium'>Price</th>
//               <th className=' text-center fw-medium'>Sqft</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr className='bg-color-1'>
//               <td className=' text-center fw-light'>{property.type}</td>
//               <td className=' text-center fw-light'>{property.propertyType}</td>
//               <td className=' text-center fw-light'>{formatPrice(property.price)}</td>
//               <td className=' text-center fw-light'>{property.totalArea}</td>
//             </tr>
//           </tbody>
//         </table>
//         <div className={`card-text1 ${expandedDescriptions[index] ? 'expanded' : 'collapsed'}`} onClick={() => toggleDescription(index)} style={{ cursor: 'pointer' }}>
//           <span className='fw-bold text-capitalize'>{property.title}</span>
//           <div dangerouslySetInnerHTML={{ __html: expandedDescriptions[index] ? property.propertyDescription : `${property.propertyDescription.slice(0, 100)}...` }} />
//           <span style={{ color: 'blue', marginLeft: '0px' }} className='fw-bold text-black'>
//             {expandedDescriptions[index] ? 'Show Less' : 'Show More'}
//           </span>
//         </div>
//         <div className='d-flex mt-3'>
//           <Link to={`/viewalldetails/${property._id}`} className='ms-auto'>
//             <button className='btn btn-warning opacity-75 fw-bold text-white'>More Details</button>
//           </Link>
//           <button className='btn btn-outline-dark ms-2 fw-bold' onClick={() => setModalShow(true)}>Enquiry</button>
//           <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
//         </div>
//       </div>
//     </div>
//   ))}
// </div>

//           {/* Pagination controls */}
//           <div className="d-flex justify-content-center mt-4">
//             <nav>
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button onClick={prevPage} className="page-link">Previous</button>
//                 </li>
//                 {[...Array(Math.ceil(data.length / propertiesPerPage)).keys()].map((number) => (
//                   <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
//                     <button onClick={() => paginate(number + 1)} className="page-link">
//                       {number + 1}
//                     </button>
//                   </li>
//                 ))}
//                 <li className={`page-item ${currentPage === Math.ceil(data.length / propertiesPerPage) ? 'disabled' : ''}`}>
//                   <button onClick={nextPage} className="page-link">Next</button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//         <div className='col'>
//           <PropertyFilter />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Appartment;

// import React, { useEffect, useState, useRef } from 'react';
// import PropertyFilter from '../Filterpages/FilterComponent';
// import Filterpageproperty from '../Filterpages/FilterpageView';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { API_BASE_URL } from '../../utils';
// import { Col, Row } from 'react-bootstrap';
// import EnquiryFormModal from '../Enquiry/Enquiry';
// import "./Sub.css";

// function Appartment({ categoryId }) {
//   const [data, setProperties] = useState([]);
//   const [expandedDescriptions, setExpandedDescriptions] = useState({});
//   const [modalShow, setModalShow] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [propertiesPerPage] = useState(6); // Adjust as needed
//   const [viewType, setViewType] = useState('grid'); // 'grid' or 'list'
//   const scrollRef = useRef(null);

//   useEffect(() => {
//     axios.get(`${API_BASE_URL}/property/getproperty`, {
//       params: {
//         propertyType: 'Appartments'
//       }
//     })
//       .then((response) => {
//         setProperties(response.data.data.properties);
//       })
//       .catch((error) => {
//         console.error('Error fetching properties:', error);
//       });
//   }, [categoryId]);

//   useEffect(() => {
//     // Scroll a bit higher than the top of the component when it mounts
//     if (scrollRef.current) {
//       scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
//       window.scrollBy(0, -800); // Scroll up by 100 pixels
//     }
//   }, []);

//   const toggleDescription = (index) => {
//     setExpandedDescriptions(prevState => ({
//       ...prevState,
//       [index]: !prevState[index]
//     }));
//   };

//   const formatPrice = (price) => {
//     if (price >= 10000000) {
//       return (price / 10000000).toFixed(2) + ' Cr';
//     } else if (price >= 100000) {
//       return (price / 100000).toFixed(2) + ' L';
//     } else {
//       return price.toString();
//     }
//   };

//   // Pagination logic
//   const indexOfLastProperty = currentPage * propertiesPerPage;
//   const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
//   const currentProperties = data.slice(indexOfFirstProperty, indexOfLastProperty);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);
//   const nextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(data.length / propertiesPerPage)));
//   const prevPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));

//   // Toggle view type
//   const toggleViewType = () => {
//     setViewType(prevType => prevType === 'grid' ? 'list' : 'grid');
//   };

//   return (
//     <div className='container' ref={scrollRef}>
//       <Row>
//         <Col md={12} className='filter-col'>
//           <Filterpageproperty />
//         </Col>
//       </Row>
//       <Row>
//         <Col>
//           <nav className='ms-3'>
//             <ol className="breadcrumb">
//               <li className="breadcrumb-item"><Link to={"/"} className='text-decoration-none text-black'>Chennai Auction Property</Link></li>
//               <li className="breadcrumb-item active">Appartments</li>
//             </ol>
//           </nav>
//         </Col>
//       </Row>
//       <Row>
//         <Col md={12}>
//           <button onClick={toggleViewType} className="btn btn-primary mb-3">
//             {viewType === 'grid' ? 'Switch to List View' : 'Switch to Grid View'}
//           </button>
//         </Col>
//       </Row>
//       <Row>
//         <Col md={viewType === 'grid' ? 8 : 12}>
//           <div className={viewType === 'grid' ? 'grid-view' : 'list-view'}>
//             {currentProperties.map((property, index) => (
//               <div className={`card-container1 mt-5 mb-5 ${viewType}`} key={index}>
//                 <div className="card-header1">
//                   <img src={property.propertyImages[0].propertyImage} alt="card-image" className="card-image1" />
//                 </div>
//                 <div className="card-body1 m-2">
//                   <h4 className="card-subtitle1">{property.propertyTitle}</h4>
//                   <h6 className="card-title1">
//                     <span className='text-capitalize' style={{fontSize: "12px", fontWeight:"200px"}}><i className="fa-solid fa-location-dot"></i> {property.address}</span>
//                   </h6>
//                   <table className="table table-striped-columns border border-2">
//                     <thead>
//                       <tr className='bg-color-2'>
//                         <th className='text-center fw-medium'>Type</th>
//                         <th className='text-center fw-medium'>Property Type</th>
//                         <th className='text-center fw-medium'>Price</th>
//                         <th className='text-center fw-medium'>Sqft</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr className='bg-color-1'>
//                         <td className='text-center fw-light'>{property.type}</td>
//                         <td className='text-center fw-light'>{property.propertyType}</td>
//                         <td className='text-center fw-light'>{formatPrice(property.price)}</td>
//                         <td className='text-center fw-light'>{property.totalArea}</td>
//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className={`card-text1 ${expandedDescriptions[index] ? 'expanded' : 'collapsed'}`} onClick={() => toggleDescription(index)} style={{ cursor: 'pointer' }}>
//                     <span className='fw-bold text-capitalize'>{property.title}</span>
//                     <div dangerouslySetInnerHTML={{ __html: expandedDescriptions[index] ? property.propertyDescription : `${property.propertyDescription.slice(0, 100)}...` }} />
//                     <span style={{ color: 'blue', marginLeft: '0px' }} className='fw-bold text-black'>
//                       {expandedDescriptions[index] ? 'Show Less' : 'Show More'}
//                     </span>
//                   </div>
//                   <div className='d-flex mt-3'>
//                     <Link to={`/viewalldetails/${property._id}`} className='ms-auto'>
//                       <button className='btn btn-warning opacity-75 fw-bold text-white'>More Details</button>
//                     </Link>
//                     <button className='btn btn-outline-dark ms-2 fw-bold' onClick={() => setModalShow(true)}>Enquiry</button>
//                     <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>

//           {/* Pagination controls */}
//           <div className="d-flex justify-content-center mt-4">
//             <nav>
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button onClick={prevPage} className="page-link">Previous</button>
//                 </li>
//                 {[...Array(Math.ceil(data.length / propertiesPerPage)).keys()].map((number) => (
//                   <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
//                     <button onClick={() => paginate(number + 1)} className="page-link">
//                       {number + 1}
//                     </button>
//                   </li>
//                 ))}
//                 <li className={`page-item ${currentPage === Math.ceil(data.length / propertiesPerPage) ? 'disabled' : ''}`}>
//                   <button onClick={nextPage} className="page-link">Next</button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </Col>
//         <Col md={viewType === 'grid' ? 4 : 12}>
//           <PropertyFilter />
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default Appartment;
// import React, { useEffect, useState, useRef } from 'react';
// import PropertyFilter from '../Filterpages/FilterComponent';
// import Filterpageproperty from '../Filterpages/FilterpageView';
// import { Link } from 'react-router-dom';
// import axios from 'axios';
// import { API_BASE_URL } from '../../utils';
// import { Col, Row } from 'react-bootstrap';
// import EnquiryFormModal from '../Enquiry/Enquiry';
// import "./Sub.css";

// const ITEMS_PER_PAGE = 3; // Set to 2 items per page

// function Appartment({ categoryId }) {
//   const [data, setProperties] = useState([]);
//   const [expandedDescriptions, setExpandedDescriptions] = useState({});
//   const [modalShow, setModalShow] = useState(false);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [viewType, setViewType] = useState('grid'); // 'grid' or 'list'
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const scrollRef = useRef(null);

//   useEffect(() => {
//     axios.get(`${API_BASE_URL}/property/getproperty`, {
//       params: {
//         propertyType: 'Appartments'
//       }
//     })
//       .then((response) => {
//         setProperties(response.data.data.properties);
//         setFilteredProperties(response.data.data.properties);
//       })
//       .catch((error) => {
//         console.error('Error fetching properties:', error);
//       });
//   }, [categoryId]);

//   useEffect(() => {
//     if (scrollRef.current) {
//       scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
//       window.scrollBy(0, -800); 
//     }
//   }, []);

//   const toggleDescription = (index) => {
//     setExpandedDescriptions(prevState => ({
//       ...prevState,
//       [index]: !prevState[index]
//     }));
//   };

//   const formatPrice = (price) => {
//     if (price >= 10000000) {
//       return (price / 10000000).toFixed(2) + ' Cr';
//     } else if (price >= 100000) {
//       return (price / 100000).toFixed(2) + ' L';
//     } else {
//       return price.toString();
//     }
//   };

//   const indexOfLastProperty = currentPage * ITEMS_PER_PAGE;
//   const indexOfFirstProperty = indexOfLastProperty - ITEMS_PER_PAGE;
//   const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);
//   const totalPages = Math.ceil(filteredProperties.length / ITEMS_PER_PAGE);

//   const paginate = (pageNumber) => setCurrentPage(pageNumber);
//   const nextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
//   const prevPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));

//   const toggleViewType = () => {
//     setViewType(prevType => prevType === 'grid' ? 'list' : 'grid');
//   };

//   const handleFilter = (filteredData) => {
//     setFilteredProperties(filteredData);
//     setCurrentPage(1); // Reset to first page on filter change
//   };

//   return (
//     <div className='container' ref={scrollRef}>
//       <Row>
//         <Col md={12} className='filter-col'>
//           <Filterpageproperty />
//         </Col>
//       </Row>
//       <Row>
//         <Col>
//           <nav className='ms-3'>
//             <ol className="breadcrumb">
//               <li className="breadcrumb-item"><Link to={"/"} className='text-decoration-none text-black'>Chennai Auction Property</Link></li>
//               <li className="breadcrumb-item active">Appartments</li>
//             </ol>
//           </nav>
//         </Col>
//       </Row>
//       <Row>
//         <Col md={12}>
//           <button onClick={toggleViewType} className="btn btn-primary mb-3">
//             {viewType === 'grid' ? 'Switch to List View' : 'Switch to Grid View'}
//           </button>
//         </Col>
//       </Row>
//       <Row>
//         <Col md={viewType === 'grid' ? 8 : 12}>
//           <div className={viewType === 'grid' ? 'grid-view' : 'list-view'}>
//             {currentProperties.map((property, index) => (
//               <div className={`card-container1 mt-5 mb-5 ${viewType}` } key={index}>
//                 <div className="card-header1">
//                   <img src={property.propertyImages[0].propertyImage} alt="card-image" className="card-image1" />
//                 </div>
//                 <div className="card-body1 m-2">
//                   <h4 className="card-subtitle1">{property.propertyTitle}</h4>
//                   <h6 className="card-title1">
//                     <span className='text-capitalize' style={{fontSize: "12px", fontWeight:"200px"}}><i className="fa-solid fa-location-dot"></i> {property.address}</span>
//                   </h6>
//                   <table className="table table-striped-columns border border-2">
//                     <thead>
//                       <tr className='bg-color-2'>
//                         <th className='text-center fw-medium'>Type</th>
//                         <th className='text-center fw-medium'>Property Type</th>
//                         <th className='text-center fw-medium'>Price</th>
//                         <th className='text-center fw-medium'>Sqft</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       <tr className='bg-color-1'>
//                         <td className='text-center fw-light'>{property.type}</td>
//                         <td className='text-center fw-light'>{property.propertyType}</td>
//                         <td className='text-center fw-light'>{formatPrice(property.price)}</td>
//                         <td className='text-center fw-light'>{property.totalArea}</td>
//                       </tr>
//                     </tbody>
//                   </table>
//                   <div className={`card-text1 ${expandedDescriptions[index] ? 'expanded' : 'collapsed'}`} onClick={() => toggleDescription(index)} style={{ cursor: 'pointer' }}>
//                     <span className='fw-bold text-capitalize'>{property.title}</span>
//                     <div dangerouslySetInnerHTML={{ __html: expandedDescriptions[index] ? property.propertyDescription : `${property.propertyDescription.slice(0, 100)}...` }} />
//                     <span style={{ color: 'blue', marginLeft: '0px' }} className='fw-bold text-black'>
//                       {expandedDescriptions[index] ? 'Show Less' : 'Show More'}
//                     </span>
//                   </div>
//                   <div className='d-flex mt-3'>
//                     <Link to={`/viewalldetails/${property._id}`} className='ms-auto'>
//                       <button className='btn btn-warning opacity-75 fw-bold text-white'>More Details</button>
//                     </Link>
//                     <button className='btn btn-outline-dark ms-2 fw-bold' onClick={() => setModalShow(true)}>Enquiry</button>
//                     <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>

//           <div className="d-flex justify-content-center mt-4">
//             <nav>
//               <ul className="pagination">
//                 <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
//                   <button onClick={prevPage} className="page-link">Previous</button>
//                 </li>
//                 {[...Array(totalPages).keys()].map((number) => (
//                   <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
//                     <button onClick={() => paginate(number + 1)} className="page-link">
//                       {number + 1}
//                     </button>
//                   </li>
//                 ))}
//                 <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
//                   <button onClick={nextPage} className="page-link">Next</button>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </Col>
//         <Col md={viewType === 'grid' ? 4 : 12}>
//           <PropertyFilter onFilter={handleFilter} />
//         </Col>
//       </Row>
//     </div>
//   );
// }

// export default Appartment;

import React, { useEffect, useState, useRef } from 'react';
import PropertyFilter from '../Filterpages/FilterComponent';
import Filterpageproperty from '../Filterpages/FilterpageView';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../utils';
import { Col, Row } from 'react-bootstrap';
import EnquiryFormModal from '../Enquiry/Enquiry';
import "./Sub.css";

const ITEMS_PER_PAGE = 2; // Set to 3 items per page

function Appartment({ categoryId }) {
  // const [data, setProperties] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewType, setViewType] = useState('grid'); // 'grid' or 'list'
  const [filteredProperties, setFilteredProperties] = useState([]);
  const scrollRef = useRef(null);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/property/getproperty`, {
      params: {
        propertyType: 'Appartments'
      }
    })
      .then((response) => {
        // setProperties(response.data.data.properties);
        setFilteredProperties(response.data.data.properties);
      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
      });
  }, [categoryId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      window.scrollBy(0, -800); 
    }
  }, []);

  const toggleDescription = (index) => {
    setExpandedDescriptions(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return (price / 10000000).toFixed(2) + ' Cr';
    } else if (price >= 100000) {
      return (price / 100000).toFixed(2) + ' L';
    } else {
      return price.toString();
    }
  };

  const indexOfLastProperty = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstProperty = indexOfLastProperty - ITEMS_PER_PAGE;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);
  const totalPages = Math.ceil(filteredProperties.length / ITEMS_PER_PAGE);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  const prevPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));

  const toggleViewType = () => {
    setViewType(prevType => prevType === 'grid' ? 'list' : 'grid');
  };

  const handleFilter = (filteredData) => {
    setFilteredProperties(filteredData);
    setCurrentPage(1); // Reset to first page on filter change
  };

  return (
    <div className='container' ref={scrollRef}>
      <Row>
        <Col md={12} className='filter-col'>
          <Filterpageproperty />
        </Col>
      </Row>
      <Row>
        <Col>
          <nav className='ms-3'>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={"/"} className='text-decoration-none text-black'>Chennai Auction Property</Link></li>
              <li className="breadcrumb-item active">Appartments</li>
            </ol>
          </nav>
        </Col>
     
        <Col md={6}  className='text-end'>
          <button onClick={toggleViewType} className="btn btn-primary mb-3  viewbutton">
            {viewType === 'grid' ? 'Switch to Grid View' : 'Switch to List View'}
          </button>
        </Col>
      </Row>
      <Row className=''>
        <Col md={viewType === 'grid' ? 8 : 8}>
          <div className={viewType === 'grid' ? 'grid-view' : 'list-view'}>
            {currentProperties.map((property, index) => (
              <div className={`card-container1 mt-5 mb-5 ${viewType}` } key={index}>
                <div className="card-header1">
                  <img src={property.propertyImages[0].propertyImage} alt="card-image" className="card-image1" />
                </div>
                <div className="card-body1 m-2">
                  <h4 className="card-subtitle1">{property.propertyTitle}</h4>
                  <h6 className="card-title1">
                    <span className='text-capitalize' style={{fontSize: "12px", fontWeight:"200px"}}><i className="fa-solid fa-location-dot"></i> {property.address}</span>
                  </h6>
                  <table className="table table-striped-columns border border-2">
                    <thead>
                      <tr className='bg-color-2'>
                        <th className='text-center fw-medium'>Type</th>
                        <th className='text-center fw-medium'>Property Type</th>
                        <th className='text-center fw-medium'>Price</th>
                        <th className='text-center fw-medium'>Sqft</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='bg-color-1'>
                        <td className='text-center fw-light'>{property.type}</td>
                        <td className='text-center fw-light'>{property.propertyType}</td>
                        <td className='text-center fw-light'>{formatPrice(property.price)}</td>
                        <td className='text-center fw-light'>{property.totalArea}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={`card-text1 ${expandedDescriptions[index] ? 'expanded' : 'collapsed'}`} onClick={() => toggleDescription(index)} style={{ cursor: 'pointer' }}>
                    <span className='fw-bold text-capitalize'>{property.title}</span>
                    <div dangerouslySetInnerHTML={{ __html: expandedDescriptions[index] ? property.propertyDescription : `${property.propertyDescription.slice(0, 100)}...` }} />
                    {/* <span style={{ color: 'blue', marginLeft: '0px' }} className='fw-bold text-black'>
                      {expandedDescriptions[index] ? 'Show Less' : 'Show More'} */}
                    {/* </span> */}
                  </div>
                  <div className='d-flex mt-3'>
                    <Link to={`/viewalldetails/${property._id}`} className='ms-auto'>
                      <button className='btn btn-warning opacity-75 fw-bold text-white'>More Details</button>
                    </Link>
                    <button className='btn btn-outline-dark ms-2 fw-bold' onClick={() => setModalShow(true)}>Enquiry</button>
                    <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center mt-4">
            <nav>
              <ul className="pagination">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button onClick={prevPage} className="page-link">Previous</button>
                </li>
                {[...Array(totalPages).keys()].map((number) => (
                  <li key={number} className={`page-item ${currentPage === number + 1 ? 'active' : ''}`}>
                    <button onClick={() => paginate(number + 1)} className="page-link">
                      {number + 1}
                    </button>
                  </li>
                ))}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                  <button onClick={nextPage} className="page-link">Next</button>
                </li>
              </ul>
            </nav>
          </div>
        </Col>
        <Col md={viewType === 'grid' ? 4 : 4} className='propertyfilter'>
          <PropertyFilter onFilter={handleFilter} />
        </Col>
      </Row>
    </div>
  );
}

export default Appartment;
