

// import React, { useState } from 'react';
// import { Form } from 'react-bootstrap';
// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';
// import filterlogo from "../Filterpages/filter.png";
// import axios from 'axios';
// import { API_BASE_URL } from '../../utils';
// import qs from 'qs'; // Import qs library
// import { useNavigate } from 'react-router-dom';

// const PropertyFilter = () => {
//     const [priceRange, setPriceRange] = useState([100000, 10000000]);
//     const [totalArea, setTotalArea] = useState([0, 10000]); // Default range for total area
//     const [propertyFacing, setPropertyFacing] = useState([]);
//     const [filteredProperties, setFilteredProperties] = useState([]);
//     const navigate = useNavigate(); // Get navigate function from useNavigate()

//     const handleSearch = () => {
//         const [minPrice, maxPrice] = priceRange;
//         const [minArea, maxArea] = totalArea;
        
//         // Prepare params for the API request
//         let params = {
//             'price[gte]': minPrice,
//             'price[lte]': maxPrice,
//             'totalArea[gte]': minArea,
//             'totalArea[lte]': maxArea,
//             'propertyFacing': propertyFacing // Send array as it is
//         };

//         axios.get(`${API_BASE_URL}/property/getproperty`, {
//             params,
//             paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }) // Serialize array properly
//         })
//         .then((response) => {
//             const propertiesData = response?.data?.data?.properties;
//             if (Array.isArray(propertiesData)) {
//                 setFilteredProperties(propertiesData);
//                 // Navigate to /filterpageview with filteredProperties as state
//                 navigate('/filterpageview', { state: { filteredProperties: propertiesData } });
//             } else {
//                 console.error('API response is not an array:', response.data);
//             }
//         })
//         .catch((error) => {
//             console.error('Error fetching properties:', error);
//         });
//     };

//     const handlePropertyFacingChange = (event) => {
//         const { value, checked } = event.target;

//         if (value === "all") {
//             setPropertyFacing(checked ? [] : []);
//         } else {
//             let updatedFacing = [...propertyFacing];

//             if (checked && !updatedFacing.includes(value)) {
//                 updatedFacing.push(value); // Add the value if checked and not already present
//             } else {
//                 updatedFacing = updatedFacing.filter(face => face !== value); // Remove the value if unchecked
//             }

//             setPropertyFacing(updatedFacing); // Update state with the new array
//         }
//     };

//     const formatPrice = (price) => {
//         if (price >= 10000000) {
//             return (price / 10000000).toFixed(2) + ' Cr';
//         } else if (price >= 100000) {
//             return (price / 100000).toFixed(2) + ' L';
//         } else {
//             return price.toString();
//         }
//     };

//     const formatArea = (area) => {
//         return area.toString() + ' sqft'; // Adjust formatting as per your requirements
//     };

//     return (
//         <div className="filter-container p-4">
//             <h5 className="text-center fw-light fs-4" style={{ cursor: 'pointer' }}>
//                 Filter Properties
//                 <img src={filterlogo} width={"20px"} alt="filter icon" className="ms-2" />
//             </h5>

//             <div className="mt-3">
//                 {/* Property Facing Checkboxes */}
//                 <Form>
//                     <label className="filter-title">Property Facing</label>
//                     <div className="d-flex flex-wrap justify-content-around mt-3">
//                         <Form.Check
//                             className="ms-2 mb-2"
//                             type="checkbox"
//                             label="North"
//                             value="north"
//                             checked={propertyFacing.includes("north")}
//                             onChange={handlePropertyFacingChange}
//                         />
//                         <Form.Check
//                             className="ms-2 mb-2"
//                             type="checkbox"
//                             label="South"
//                             value="south"
//                             checked={propertyFacing.includes("south")}
//                             onChange={handlePropertyFacingChange}
//                         />
//                         <Form.Check
//                             className="ms-2 mb-2"
//                             type="checkbox"
//                             label="East"
//                             value="east"
//                             checked={propertyFacing.includes("east")}
//                             onChange={handlePropertyFacingChange}
//                         />
//                         <Form.Check
//                             className="ms-2 mb-2"
//                             type="checkbox"
//                             label="West"
//                             value="west"
//                             checked={propertyFacing.includes("west")}
//                             onChange={handlePropertyFacingChange}
//                         />
//                         <Form.Check
//                             className="ms-2 mb-2"
//                             type="checkbox"
//                             label="All"
//                             value="all"
//                             checked={propertyFacing.length === 0}
//                             onChange={handlePropertyFacingChange}
//                         />
//                     </div>
//                 </Form>

//                 <div className='border-bottom border-top mt-3 pt-3'>
//                     <label className="filter-title">Property Price</label>
//                     <Slider
//                         range
//                         min={0}
//                         max={10000000}
//                         step={50000}
//                         defaultValue={priceRange}
//                         onChange={(value) => setPriceRange(value)}
//                         className="w-75 m-auto mt-3"
//                         style={{ fontSize: "13px" }}
//                         handleStyle={{
//                             borderColor: "#eb6753",
//                             backgroundColor: "#eb6753",
//                         }}
//                         trackStyle={{
//                             backgroundColor: "#eb6753",
//                         }}
//                         railStyle={{
//                             backgroundColor: "#ddd",
//                         }}
//                     />
//                     <p className="text-center mt-2 mb-0">Selected Price Range: ₹{formatPrice(priceRange[0])} - ₹{formatPrice(priceRange[1])}</p>
//                 </div>

//                 <div className='border-bottom mt-3 pt-3'>
//                     <label className="filter-title">Property Area</label>
//                     <Slider
//                         range
//                         min={0}
//                         max={10000}
//                         step={100}
//                         defaultValue={totalArea}
//                         onChange={(value) => setTotalArea(value)}
//                         className="w-75 m-auto  mt-3"
//                         handleStyle={{
//                             borderColor: "#eb6753",
//                             backgroundColor: "#eb6753",
//                         }}
//                         trackStyle={{
//                             backgroundColor: "#eb6753",
//                         }}
//                         railStyle={{
//                             backgroundColor: "#ddd",
//                         }}
//                     />
//                     <p className="text-center mt-2 mb-0">Selected Total Area: {formatArea(totalArea[0])} - {formatArea(totalArea[1])}</p>
//                 </div>

//             </div>

//             <div className='justify-content-center text-center mt-4'>
//                 <button className="filter-search-btn btn btn-primary text-white rounded-pill px-4 py-2" onClick={handleSearch}>
//                     Filter <i className="fa-solid fa-filter ms-2" />
//                 </button>
//             </div>
//         </div>
//     );
// };

// export default PropertyFilter;
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import filterlogo from "../Filterpages/filter.png";
import axios from 'axios';
import { API_BASE_URL } from '../../utils';
import qs from 'qs'; // Import qs library
import { useNavigate } from 'react-router-dom';

const PropertyFilter = () => {
    const [priceRange, setPriceRange] = useState([100000, 10000000]);
    const [totalArea, setTotalArea] = useState([0, 10000]); // Default range for total area
    const [propertyFacing, setPropertyFacing] = useState([]);
    const [filteredProperties, setFilteredProperties] = useState([]);
    const navigate = useNavigate(); // Get navigate function from useNavigate()

    const handleSearch = () => {
        const [minPrice, maxPrice] = priceRange;
        const [minArea, maxArea] = totalArea;
        
        // Prepare params for the API request
        let params = {
            'price[gte]': minPrice,
            'price[lte]': maxPrice,
            'totalArea[gte]': minArea,
            'totalArea[lte]': maxArea,
            'propertyFacing': propertyFacing   // Send array as it is, or empty if "All" is selected
        };

        axios.get(`${API_BASE_URL}/property/getproperty`, {
            params,
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }) // Serialize array properly
        })
        .then((response) => {
            const propertiesData = response?.data?.data?.properties;
            if (Array.isArray(propertiesData)) {
                setFilteredProperties(propertiesData);
                // Navigate to /filterpageview with filteredProperties as state
                navigate('/filterheader', { state: { filteredProperties: propertiesData } });
            } else {
                console.error('API response is not an array:', response.data);
            }
        })
        .catch((error) => {
            console.error('Error fetching properties:', error);
        });
    };

    const handlePropertyFacingChange = (event) => {
        const { value, checked } = event.target;

        if (value === "all") {
            setPropertyFacing(checked ? [] : ["north", "south", "east", "west"]); // Clear array or set all options
        } else {
            let updatedFacing = [...propertyFacing];

            if (checked && !updatedFacing.includes(value)) {
                updatedFacing.push(value); // Add the value if checked and not already present
            } else {
                updatedFacing = updatedFacing.filter(face => face !== value); // Remove the value if unchecked
            }

            setPropertyFacing(updatedFacing); // Update state with the new array
        }
    };

    const formatPrice = (price) => {
        if (price >= 10000000) {
            return (price / 10000000).toFixed(2) + ' Cr';
        } else if (price >= 100000) {
            return (price / 100000).toFixed(2) + ' L';
        } else {
            return price.toString();
        }
    };

    const formatArea = (area) => {
        return area.toString() + ' sqft'; // Adjust formatting as per your requirements
    };

    return (
        <div className="filter-container p-4">
            <h5 className="text-center fw-light fs-4" style={{ cursor: 'pointer' }}>
                Filter Properties
                <img src={filterlogo} width={"20px"} alt="filter icon" className="ms-2" />
            </h5>

            <div className="mt-3">
                {/* Property Facing Checkboxes */}
                <Form>
                    <label className="filter-title">Property Facing</label>
                    <div className="d-flex flex-wrap justify-content-around mt-3">
                        <Form.Check
                            className="ms-2 mb-2"
                            type="checkbox"
                            label="North"
                            value="north"
                            checked={propertyFacing.includes("north")}
                            onChange={handlePropertyFacingChange}
                        />
                        <Form.Check
                            className="ms-2 mb-2"
                            type="checkbox"
                            label="South"
                            value="south"
                            checked={propertyFacing.includes("south")}
                            onChange={handlePropertyFacingChange}
                        />
                        <Form.Check
                            className="ms-2 mb-2"
                            type="checkbox"
                            label="East"
                            value="east"
                            checked={propertyFacing.includes("east")}
                            onChange={handlePropertyFacingChange}
                        />
                        <Form.Check
                            className="ms-2 mb-2"
                            type="checkbox"
                            label="West"
                            value="west"
                            checked={propertyFacing.includes("west")}
                            onChange={handlePropertyFacingChange}
                        />
                        <Form.Check
                            className="ms-2 mb-2"
                            type="checkbox"
                            label="All"
                            value="all"
                            checked={propertyFacing.length === 0}
                            onChange={handlePropertyFacingChange}
                        />
                    </div>
                </Form>

                <div className='border-bottom border-top mt-3 pt-3'>
                    <label className="filter-title">Property Price</label>
                    <Slider
                        range
                        min={0}
                        max={10000000}
                        step={50000}
                        defaultValue={priceRange}
                        onChange={(value) => setPriceRange(value)}
                        className="w-75 m-auto mt-3"
                        style={{ fontSize: "13px" }}
                        handleStyle={{
                            borderColor: "#eb6753",
                            backgroundColor: "#eb6753",
                        }}
                        trackStyle={{
                            backgroundColor: "#eb6753",
                        }}
                        railStyle={{
                            backgroundColor: "#ddd",
                        }}
                    />
                    <p className="text-center mt-2 mb-0">Selected Price Range: ₹{formatPrice(priceRange[0])} - ₹{formatPrice(priceRange[1])}</p>
                </div>

                <div className='border-bottom mt-3 pt-3'>
                    <label className="filter-title">Property Area</label>
                    <Slider
                        range
                        min={0}
                        max={10000}
                        step={100}
                        defaultValue={totalArea}
                        onChange={(value) => setTotalArea(value)}
                        className="w-75 m-auto  mt-3"
                        handleStyle={{
                            borderColor: "#eb6753",
                            backgroundColor: "#eb6753",
                        }}
                        trackStyle={{
                            backgroundColor: "#eb6753",
                        }}
                        railStyle={{
                            backgroundColor: "#ddd",
                        }}
                    />
                    <p className="text-center mt-2 mb-0">Selected Total Area: {formatArea(totalArea[0])} - {formatArea(totalArea[1])}</p>
                </div>

            </div>

            <div className='justify-content-center text-center mt-4'>
                <button className=" btn btn-danger text-white rounded-pill px-4 py-2" onClick={handleSearch}>
                    Filter <i className="fa-solid fa-filter ms-2" />
                </button>
            </div>

            {/* Render filtered properties */}
            {filteredProperties.length > 0 && (
                <div className="mt-4">
                    {/* <h5>Filtered Properties</h5> */}
                    <ul>
                        {filteredProperties.map(property => (
                            <li key={property.id}>{property.name}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default PropertyFilter;
