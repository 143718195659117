import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Filterpageproperty from './FilterpageView'
import FilterContent from './FilterContent'
import PropertyFilter from './FilterComponent'
import BreadCrumbs from './BreadCrumbs'

function FilterHeader() {
    return (
        <div>
            <Row>
                <Col md={12}>
                    <Filterpageproperty />
                </Col>

            </Row>
                    <div className="property-list mt-2 container">
       <BreadCrumbs></BreadCrumbs>




                        <Row >
                            <Col xs={12} sm={12} md={8} className="">
                                <FilterContent />
                            </Col>


                            <Col md={4}>
                                <PropertyFilter />
                            </Col>
                        </Row>
                    </div>
           
        </div>
    )
}

export default FilterHeader
