
// import "../Style/FindPropertiesstyle.css";
// import React, { useEffect, useState } from 'react';
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Container from "react-bootstrap/Container";
// import { FaArrowRight, FaArrowUp } from "react-icons/fa";
// import axios from "axios";
// import { API_BASE_URL } from "../utils";
// import find1 from "../Images/find1.png";
// import { Link } from "react-router-dom";
// import find2 from "../Images/find2.jpg";
// import find3 from "../Images/find3.png";
// import find4 from "../Images/find4.png";
// import find5 from "../Images/find5.png";

// function FindProperties() {
//   const [locations, setLocations] = useState([]);
//   const [showAll, setShowAll] = useState(false); // State to manage visibility of all locations

//   useEffect(() => {
//     axios.get(`${API_BASE_URL}/property/propertiesbylocation`)
//       .then((response) => {
//         const data = response.data.data;
//         setLocations(data);
//       })
//       .catch((error) => {
//         console.error('Error fetching properties by location:', error);
//       });
//   }, []);

//   // Function to toggle the visibility state
//   const toggleShowAll = () => {
//     setShowAll(!showAll);
//   };

//   return (
//     <Container className="mt-5">
//       <Row>
//         <Col>
//           <div className="text-center">
//             <h4>Find Properties in These Cities</h4>
//             <p>Explore properties in prime cities across the country. Whether you're looking for urban excitement, suburban comfort, or scenic retreats, our listings cover all your needs. Find your ideal home or investment in vibrant neighborhoods with top amenities. Start your search now and discover the perfect city for you!</p>
//           </div>
//         </Col>
//       </Row>
//       <Row className="gy-4 img-control">
//         {locations.slice(0, 1).map((location, index) => (
//           <Col xs={12} md={6} lg={4} key={index} className="automatic">
//             <div className="contain">
//               <img src={location?.propertyImage[0]?.propertyImages} alt={location.location} className="find-img img-fluid" />
//               <div className="overlay-find1">
//                 <button className="btn btn-1find text-start text-white">
//                   <p className="m-0 ms-2 text-start paragraph">{location.count} Properties</p>
//                   {location.location}
//                 </button>
//               </div>
//             </div>
//           </Col>
//         ))}
//         <Col xs={12} md={6} lg={8}>
//           <Row className="gy-4 img-control-1 win-size">
//             {locations.slice(1, showAll ? locations.length : 5).map((location, index) => (
//               <Col xs={12} sm={6} lg={6} key={index} className="splt-cl">
//                 <div className="contain">
//                   <img src={find2} alt={location.location} className="find-img img-fluid" />
//                   <div className="overlay-find">
//                     <button className="btn btn-1 text-start text-white">
//                       <p className="m-0 ms-2 text-start paragraph">{location.count} Properties</p>
//                       {location.location}
//                     </button>
//                   </div>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </Col>
//       </Row>
//       <Row>
//         <Col className="text-center mt-4">
//           <button className="btn btn-danger" onClick={toggleShowAll}>
//             {showAll ? 'Show Less' : 'See All Cities'} <FaArrowRight />
//           </button>
//         </Col>
//       </Row>
//     </Container>
//   );
// }

// export default FindProperties;
  import "../Style/FindPropertiesstyle.css";
  import React, { useEffect, useState } from 'react';
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import Container from "react-bootstrap/Container";
  import { FaArrowRight } from "react-icons/fa";
  import axios from "axios";
  import { API_BASE_URL } from "../utils";
  // import { Link } from "react-router-dom";
// import axiosInstance from "../utils/429Error";

  function FindProperties() {
    const [locations, setLocations] = useState([]);
    const [showAll, setShowAll] = useState(false); // State to manage visibility of all locations

    useEffect(() => {
      axios.get(`${API_BASE_URL}/property/propertiesbylocation`)
        .then((response) => {
          const data = response.data.data;
          setLocations(data);
          console.log(data,"Ssss findpropertiesx")
        })
        .catch((error) => {
          console.error('Error fetching properties by location:', error);
        });
    }, []);

    // Function to toggle the visibility state
    const toggleShowAll = () => {
      setShowAll(!showAll);
    };

    return (
      <Container className="mt-5">
        <Row>
          <Col>
            <div className="text-center">
              <h4>Find Properties in These Cities</h4>
              <p>Explore properties in prime cities across the country. Whether you're looking for urban excitement, suburban comfort, or scenic retreats, our listings cover all your needs. Find your ideal home or investment in vibrant neighborhoods with top amenities. Start your search now and discover the perfect city for you!</p>
            </div>
          </Col>
        </Row>
        <Row className="gy-4 img-control">
          {locations.slice(0, 1).map((location, index) => (
            <Col xs={12} md={0} lg={4} key={index} className="automatic  hide-on-small">
              <div className="contain  gdff-fd">
                {location.properties[0].propertyImages && location.properties[0].propertyImages.length > 0 && (
                  <img src={location.properties[0].propertyImages[0].propertyImage} alt={location.location} className="find-img2 img-fluid " />
                )}
                <div className="overlay-find1">
                  <button className="btn btn-1find text-start text-white">
                    <p className="m-0 ms-2 text-start paragraph">{location.count} Properties</p>
                    {location.location}
                  </button>
                </div>
              </div>
            </Col>
          ))}
          <Col  md={12} lg={8}>
            <Row className="gy-4 img-control-1 win-size">
              {locations.slice(1, showAll ? locations.length : 5).map((location, index) => (
                <Col md={6} lg={6} key={index} className="splt-cl">
                  <div className="contain" >
                    {location.properties[0].propertyImages && location.properties[0].propertyImages.length > 0 && (
                      <img src={location.properties[0].propertyImages[0].propertyImage} alt={location.location} className="find-img img-fluid" />
                    )}
                    <div className="overlay-find">
                      <button className="btn btn-1 text-start text-white">
                        <p className="m-0 ms-2 text-start paragraph">{location.count} Properties</p>
                        {location.location}
                      </button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mt-4">
            <button className="btn btn-danger" onClick={toggleShowAll}>
              {showAll ? 'Show Less' : 'See All Cities'} <FaArrowRight />
            </button>
          </Col>
        </Row>
      </Container>
    );
  }

  export default FindProperties;
