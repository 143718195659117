

// import React, { useState, useEffect } from "react";
// import { Col, Row, Container } from "react-bootstrap";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { API_BASE_URL } from "../../utils";
// import { useAuthHeaders } from "../Tokenrefresh";
// import "../../Style/ExploreStyle.css";
// const NewProducts = () => {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [subCategory, setSubCategory] = useState([]);
//   const [properties, setProperties] = useState([]);
//   const [showDetails, setShowDetails] = useState(false);
//   const headers = useAuthHeaders();
//   const navigate = useNavigate();

//   useEffect(() => {
//     AOS.init({
//       duration: 1000,
//       once: true, // Animation will trigger only once
//     });

//     axios
//       .get(`${API_BASE_URL}/getallcategory`, { headers })
//       .then((response) => {
//         const categoriesData = response?.data?.data;
//         setCategories(categoriesData);
//         if (categoriesData.length > 0) {
//           const firstCategoryId = categoriesData[0]._id;
//           setSelectedCategory(firstCategoryId);
//           fetchSubCategoryAndProperties(firstCategoryId);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching categories:", error);
//       });
//   }, [headers]);

//   const fetchSubCategoryAndProperties = (categoryId) => {
//     useEffect(()=>{


//       axios
//         .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`, { headers })
//         .then((response) => {
//           setSubCategory(response.data.data);
//           setShowDetails(true);
//         })
//         .catch((error) => {
//           console.error("Error fetching subcategory data:", error);
//         });
  
//       // Fetch properties for selected category
//       axios
//         .get(`${API_BASE_URL}/property/propertiesbysubcategory?categoryId=${categoryId}`)
//         .then((response) => {
//           const propertiesData = response.data.data;
//           setProperties(propertiesData);
//         })
//         .catch((error) => {
//           console.error("Error fetching properties data:", error);
//         });
//     },[])
//     // Fetch subcategories for selected category
//   };

//   const handleCategorySelect = (categoryId) => {
//     setSelectedCategory(categoryId);
//     fetchSubCategoryAndProperties(categoryId);
//   };
//   const handleSubCategoryClick = (subcategory) => {
//     console.log("Clicked subcategory:", subcategory);
  
//     if (subcategory && subcategory.subcategoryname) {
//       console.log("Navigating to:", `/${subcategory.subcategoryname.toLowerCase()}`);
//       navigate(`/${subcategory.subcategoryname.toLowerCase()}`);
//     } else {
//       console.error("Subcategory or its subcategoryname is not defined.", subcategory);
//     }
//   };
  
  
  

//   return (
//     <>
//       <Container>
//         <div className="text-center mt-3">
//           <h3>Explore Our Properties</h3>
//           <p>
//             Explore our diverse property listings, from apartments to villas.
//             Find your ideal family home, vacation retreat, or investment
//             opportunity. Start your search today!
//           </p>
//         </div>
//         <div className="text-center mt-2 mb-3">
//           <div className="d-flex flex-wrap justify-content-center">
//             {categories.map((category) => (
//               <button
//                 key={category._id}
//                 value={category._id}
//                 className={`btn ${
//                   selectedCategory === category._id
//                     ? "btn-outline-danger"
//                     : ""
//                 } mx-2 my-1`}
//                 onClick={() => handleCategorySelect(category._id)}
//               >
//                 {category.categoryname}
//               </button>
//             ))}
//           </div>
//         </div>

//         {showDetails && (
//           <Row className="m-auto justify-content-center">
//             {subCategory.length > 0 ? (
//               subCategory.map((category, index) => {
//                 return (
//                   <Col
//                     xs={12}
//                     sm={6}
//                     md={3}
//                     lg={3}
//                     key={category._id}
//                     className="mb-4"
//                     data-aos="fade-up"
//                     data-aos-delay={index * 100}
//                     onClick={() => handleSubCategoryClick(category)}
//                   >
//                     <div className="contain card d-flex w-75 ">
//                       <img
//                         src={category?.propertytypeimage}
//                         className="find-1 "
//                         alt="property"
//                       />
//                       <div className="overlay-9">
//                         <ul className="m-0 p-0">
//                           <li className="me-5 p-0 fs-6 fw-bold text-white">
//                             {category?.subcategoryname}
//                           </li>
//                           <p className="text-start">
//                         {properties
//                           .filter(
//                             (property) =>
//                               property.propertytype ===
//                               category.subcategoryname
//                           )
//                           .map((filteredProperty) => filteredProperty.count)
//                           .reduce((sum, count) => sum + count, 0)}{" "}
//                         properties
//                       </p>
//                         </ul>
//                       </div>
//                     </div>
//                   </Col>
//                 );
//               })
//             ) : (
//               <Col xs={12} className="text-center">
//                 <p>No properties found</p>
//               </Col>
//             )}
//           </Row>
//         )}
//       </Container>
//     </>
//   );
// };

// export default NewProducts;
// import React, { useState, useEffect } from "react";
// import { Col, Row, Container } from "react-bootstrap";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { debounce } from "lodash";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { API_BASE_URL } from "../../utils";
// import { useAuthHeaders } from "../Tokenrefresh";
// import "../../Style/ExploreStyle.css";

// const NewProducts = () => {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [subCategory, setSubCategory] = useState([]);
//   const [properties, setProperties] = useState([]);
//   const [showDetails, setShowDetails] = useState(false);
//   const [isFetching, setIsFetching] = useState(false); // Loading state for API requests
//   const [lastRequestTime, setLastRequestTime] = useState(0);
//   const headers = useAuthHeaders();
//   const navigate = useNavigate();

//   useEffect(() => {
//     AOS.init({
//       duration: 1000,
//       once: true, // Animation will trigger only once
//     });

//     axios
//       .get(`${API_BASE_URL}/getallcategory`, { headers })
//       .then((response) => {
//         const categoriesData = response?.data?.data;
//         setCategories(categoriesData);
//         if (categoriesData.length > 0) {
//           const firstCategoryId = categoriesData[0]._id;
//           setSelectedCategory(firstCategoryId);
//           fetchSubCategoryAndProperties(firstCategoryId);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching categories:", error);
//       });
//   }, [headers]);

//   const fetchSubCategoryAndProperties = debounce((categoryId) => {
//     const currentTime = Date.now();
//     const MIN_REQUEST_INTERVAL = 1000; // Minimum interval between requests (1 second)

//     if (currentTime - lastRequestTime < MIN_REQUEST_INTERVAL) {
//       console.log("Too many requests. Please wait before trying again.");
//       return;
//     }

//     setIsFetching(true);
//     setLastRequestTime(currentTime);

//     axios
//       .get(`${API_BASE_URL}/getsubcategorybycategory/${categoryId}`, { headers })
//       .then((response) => {
//         setSubCategory(response.data.data);
//         setShowDetails(true);
//         setIsFetching(false); // Reset loading state
//       })
//       .catch((error) => {
//         console.error("Error fetching subcategory data:", error);
//         setIsFetching(false); // Reset loading state on error
//         if (error.response && error.response.data.errors === "Too many requests, please try again later.") {
//           console.log("Too many requests. Retrying after delay...");
//           setTimeout(() => fetchSubCategoryAndProperties(categoryId), 2000); // Retry after 5 seconds
//         }
//       });

//     axios
//       .get(`${API_BASE_URL}/property/propertiesbysubcategory?categoryId=${categoryId}`, { headers })
//       .then((response) => {
//         const propertiesData = response.data.data;
//         setProperties(propertiesData);
//       })
//       .catch((error) => {
//         console.error("Error fetching properties data:", error);
//       });
//   }, 500); // Adjust debounce delay as needed

//   const handleCategorySelect = (categoryId) => {
//     fetchSubCategoryAndProperties(categoryId);
//     setSelectedCategory(categoryId);
//   };

//   const handleSubCategoryClick = (subcategory) => {
//     console.log("Clicked subcategory:", subcategory);

//     if (subcategory && subcategory.subcategoryname) {
//       console.log("Navigating to:", `/${subcategory.subcategoryname.toLowerCase()}`);
//       navigate(`/${subcategory.subcategoryname.toLowerCase()}`);
//     } else {
//       console.error("Subcategory or its subcategoryname is not defined.", subcategory);
//     }
//   };

//   return (
//     <>
//       <Container>
//         <div className="text-center mt-3">
//           <h3>Explore Our Properties</h3>
//           <p>
//             Explore our diverse property listings, from apartments to villas.
//             Find your ideal family home, vacation retreat, or investment
//             opportunity. Start your search today!
//           </p>
//         </div>
//         <div className="text-center mt-2 mb-3">
//           <div className="d-flex flex-wrap justify-content-center">
//             {categories.map((category) => (
//               <button
//                 key={category._id}
//                 value={category._id}
//                 className={`btn ${
//                   selectedCategory === category._id
//                     ? "btn-outline-danger"
//                     : ""
//                 } mx-2 my-1`}
//                 onClick={() => handleCategorySelect(category._id)}
//                 disabled={isFetching} // Disable button when fetching data
//               >
//                 {category.categoryname}
//               </button>
//             ))}
//           </div>
//         </div>

//         {showDetails && (
//           <Row className="m-auto justify-content-center dddd">
//             {subCategory.length > 0 ? (
//               subCategory.map((category, index) => {
//                 return (
//                   <Col
//                     xs={12}
//                     sm={6}
//                     md={3}
//                     lg={3}
//                     key={category._id}
//                     className="mb-4 fdfdf"
//                     data-aos="fade-up"
//                     data-aos-delay={index * 100}
//                     onClick={() => handleSubCategoryClick(category)}
//                   >
//                     <div className="contain rounded d-flex  ">
//                       <img
//                         src={category?.propertytypeimage}
//                         className="find-1 "
//                         alt="property"
//                       />
//                       <div className="overlay-9">
//                         <ul className="m-0 p-0">
//                           <li className="me-5 p-0   text-white" style={{fontSize:"12px",fontWeight:200}}>
//                             {category?.subcategoryname}
//                           </li>
//                           <p className="text-start" style={{fontSize:"12px",fontWeight:200}}>
//                             {properties
//                               .filter(
//                                 (property) =>
//                                   property.propertytype ===
//                                   category.subcategoryname
//                               )
//                               .map((filteredProperty) => filteredProperty.count)
//                               .reduce((sum, count) => sum + count, 0)}{" "}
//                             properties
//                           </p>
//                         </ul>
//                       </div>
//                     </div>
//                   </Col>
//                 );
//               })
//             ) : (
//               <Col xs={12} className="text-center">
//                 <p>No properties found</p>
//               </Col>
//             )}
//           </Row>
//         )}
//       </Container>
//     </>
//   );
// };

// export default NewProducts;
// import React, { useState, useEffect } from "react";
// import { Col, Row, Container } from "react-bootstrap";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { debounce } from "lodash";
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { API_BASE_URL } from "../../utils";
// import { useAuthHeaders } from "../Tokenrefresh";
// import "../../Style/ExploreStyle.css";
// // import axiosInstance from "../../utils/429Error";

// const NewProducts = () => {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [subCategory, setSubCategory] = useState([]);
//   const [properties, setProperties] = useState([]);
//   const [showDetails, setShowDetails] = useState(false);
//   const [isFetching, setIsFetching] = useState(false); // Loading state for API requests
//   const [lastRequestTime, setLastRequestTime] = useState(0);
//   const headers = useAuthHeaders();
//   const navigate = useNavigate();


//   const fetchData = async () => {
//     try {
//       const response = await  axios.get(`${API_BASE_URL}/getallcategory`, {
//         headers,
//       });
//       const categoriesData = response?.data?.data;
//       setCategories(categoriesData);
//       if (categoriesData.length > 0) {
//         const firstCategoryId = categoriesData[0]._id;
//         setSelectedCategory(firstCategoryId);
//         fetchSubCategoryAndProperties(firstCategoryId);
//       }
//     } catch (error) {
//       console.error("Error fetching categories:", error);
//     }
//   };
  
//   useEffect(() => {
//     AOS.init({
//       duration: 1000,
//       once: true, // Animation will trigger only once
//     });

//     fetchData();
//   },[fetchData]);

//   const fetchSubCategoryAndProperties = debounce(async (categoryId) => {
//     const currentTime = Date.now();
//     const MIN_REQUEST_INTERVAL = 1500; // Increased debounce delay (1.5 seconds)

//     if (currentTime - lastRequestTime < MIN_REQUEST_INTERVAL) {
//       console.log("Too many requests. Please wait before trying again.");
//       return;
//     }

//     setIsFetching(true);
//     setLastRequestTime(currentTime);

//     try {
//       const subCategoryResponse = await  axios.get(
//         `${API_BASE_URL}/getsubcategorybycategory/${categoryId}`,
//         { headers }
//       );
//       setSubCategory(subCategoryResponse.data.data);
//       setShowDetails(true);
//     } catch (error) {
//       console.error("Error fetching subcategory data:", error);
//       handleRequestError(error);
//     }

//     try {
//       const propertiesResponse = await  axios.get(
//         `${API_BASE_URL}/property/propertiesbysubcategory?categoryId=${categoryId}`,
//         { headers }
//       );
//       const propertiesData = propertiesResponse.data.data;
//       setProperties(propertiesData);
//     } catch (error) {
//       console.error("Error fetching properties data:", error);
//       handleRequestError(error);
//     }

//     setIsFetching(false); // Reset loading state
//   }, 1500); // Adjust debounce delay as needed

//   const handleCategorySelect = (categoryId) => {
//     fetchSubCategoryAndProperties(categoryId);
//     setSelectedCategory(categoryId);
//   };

//   const handleSubCategoryClick = (subcategory) => {
//     if (subcategory && subcategory.subcategoryname) {
//       navigate(`/${subcategory.subcategoryname.toLowerCase()}`);
//     } else {
//       console.error(
//         "Subcategory or its subcategoryname is not defined.",
//         subcategory
//       );
//     }
//   };

//   const handleRequestError = (error) => {
//     if (error.response && error.response.status === 429) {
//       console.log("Too many requests. Retrying after delay...");
//       setTimeout(() => fetchSubCategoryAndProperties(selectedCategory), 3000); // Retry after 3 seconds
//     } else {
//       console.error("Unhandled error:", error);
//     }
//   };

//   return (
//     <>
//       <Container>
//         <div className="text-center mt-3">
//           <h3>Explore Our Properties</h3>
//           <p>
//             Explore our diverse property listings, from apartments to villas.
//             Find your ideal family home, vacation retreat, or investment
//             opportunity. Start your search today!
//           </p>
//         </div>
//         <div className="text-center mt-2 mb-3">
//           <div className="d-flex flex-wrap justify-content-center">
//             {categories.map((category) => (
//               <button
//                 key={category._id}
//                 value={category._id}
//                 className={`btn ${
//                   selectedCategory === category._id ? "btn-outline-danger" : ""
//                 } mx-2 my-1`}
//                 onClick={() => handleCategorySelect(category._id)}
//                 disabled={isFetching} // Disable button when fetching data
//               >
//                 {category.categoryname}
//               </button>
//             ))}
//           </div>
//         </div>

//         {showDetails && (
//           <Row className="m-auto justify-content-center dddd">
//             {subCategory.length > 0 ? (
//               subCategory.map((category, index) => (
//                 <Col
//                   xs={12}
//                   sm={6}
//                   md={3}
//                   lg={3}
//                   key={category._id}
//                   className="mb-4 fdfdf"
//                   data-aos="fade-up"
//                   data-aos-delay={index * 100}
//                   onClick={() => handleSubCategoryClick(category)}
//                 >
//                   <div className="contain rounded d-flex">
//                     <img
//                       src={category?.propertytypeimage}
//                       className="find-1"
//                       alt="property"
//                     />
//                     <div className="overlay-9">
//                       <ul className="m-0 p-0">
//                         <li
//                           className="me-5 p-0 text-white"
//                           style={{ fontSize: "12px", fontWeight: 200 }}
//                         >
//                           {category?.subcategoryname}
//                         </li>
//                         <p
//                           className="text-start"
//                           style={{ fontSize: "12px", fontWeight: 200 }}
//                         >
//                           {properties
//                             .filter(
//                               (property) =>
//                                 property.propertytype ===
//                                 category.subcategoryname
//                             )
//                             .map((filteredProperty) => filteredProperty.count)
//                             .reduce((sum, count) => sum + count, 0)}{" "}
//                           properties
//                         </p>
//                       </ul>
//                     </div>
//                   </div>
//                 </Col>
//               ))
//             ) : (
//               <Col xs={12} className="text-center">
//                 <p>No properties found</p>
//               </Col>
//             )}
//           </Row>
//         )}
//       </Container>
//     </>
//   );
// };

// export default NewProducts;
import React, { useState, useEffect, useCallback } from "react";
import { Col, Row, Container } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import AOS from "aos";
import "aos/dist/aos.css";
import { API_BASE_URL } from "../../utils";
import { useAuthHeaders } from "../Tokenrefresh";
import "../../Style/ExploreStyle.css";

const NewProducts = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState([]);
  const [properties, setProperties] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // Loading state for API requests
  const [lastRequestTime, setLastRequestTime] = useState(0);
  const headers = useAuthHeaders();
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getallcategory`, {
        headers,
      });
      const categoriesData = response?.data?.data;
      setCategories(categoriesData);
      if (categoriesData.length > 0) {
        const firstCategoryId = categoriesData[0]._id;
        setSelectedCategory(firstCategoryId);
        fetchSubCategoryAndProperties(firstCategoryId);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, [headers]);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Animation will trigger only once
    });

    fetchData();
  }, [fetchData]);

  const fetchSubCategoryAndProperties = debounce(async (categoryId) => {
    const currentTime = Date.now();
    const MIN_REQUEST_INTERVAL = 1500; // Increased debounce delay (1.5 seconds)

    if (currentTime - lastRequestTime < MIN_REQUEST_INTERVAL) {
      console.log("Too many requests. Please wait before trying again.");
      return;
    }

    setIsFetching(true);
    setLastRequestTime(currentTime);

    try {
      const subCategoryResponse = await axios.get(
        `${API_BASE_URL}/getsubcategorybycategory/${categoryId}`,
        { headers }
      );
      setSubCategory(subCategoryResponse.data.data);
      setShowDetails(true);
    } catch (error) {
      console.error("Error fetching subcategory data:", error);
      handleRequestError(error);
    }

    try {
      const propertiesResponse = await axios.get(
        `${API_BASE_URL}/property/propertiesbysubcategory?categoryId=${categoryId}`,
        { headers }
      );
      const propertiesData = propertiesResponse.data.data;
      setProperties(propertiesData);
    } catch (error) {
      console.error("Error fetching properties data:", error);
      handleRequestError(error);
    }

    setIsFetching(false); // Reset loading state
  }, 1500); // Adjust debounce delay as needed

  const handleCategorySelect = (categoryId) => {
    fetchSubCategoryAndProperties(categoryId);
    setSelectedCategory(categoryId);
  };

  const handleSubCategoryClick = (subcategory) => {
    if (subcategory && subcategory.subcategoryname) {
      navigate(`/${subcategory.subcategoryname.toLowerCase()}`);
    } else {
      console.error(
        "Subcategory or its subcategoryname is not defined.",
        subcategory
      );
    }
  };

  const handleRequestError = (error) => {
    if (error.response && error.response.status === 429) {
      console.log("Too many requests. Retrying after delay...");
      setTimeout(() => fetchSubCategoryAndProperties(selectedCategory), 3000); // Retry after 3 seconds
    } else {
      console.error("Unhandled error:", error);
    }
  };

  return (
    <>
      <Container>
        <div className="text-center mt-3">
          <h3>Explore Our Properties</h3>
          <p>
            Explore our diverse property listings, from apartments to villas.
            Find your ideal family home, vacation retreat, or investment
            opportunity. Start your search today!
          </p>
        </div>
        <div className="text-center mt-2 mb-3">
          <div className="d-flex flex-wrap justify-content-center">
            {categories.map((category) => (
              <button
                key={category._id}
                value={category._id}
                className={`btn ${
                  selectedCategory === category._id ? "btn-outline-danger" : ""
                } mx-2 my-1`}
                onClick={() => handleCategorySelect(category._id)}
                disabled={isFetching} // Disable button when fetching data
              >
                {category.categoryname}
              </button>
            ))}
          </div>
        </div>

        {showDetails && (
          <Row className="m-auto justify-content-center dddd">
            {subCategory.length > 0 ? (
              subCategory.map((category, index) => (
                <Col
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  key={category._id}
                  className="mb-4 fdfdf"
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                  onClick={() => handleSubCategoryClick(category)}
                >
                  <div className="contain rounded d-flex">
                    <img
                      src={category?.propertytypeimage}
                      className="find-1"
                      alt="property"
                    />
                    <div className="overlay-9">
                      <ul className="m-0 p-0">
                        <li
                          className="me-5 p-0 text-white"
                          style={{ fontSize: "12px", fontWeight: 200 }}
                        >
                          {category?.subcategoryname}
                        </li>
                        <p
                          className="text-start"
                          style={{ fontSize: "12px", fontWeight: 200 }}
                        >
                          {properties
                            .filter(
                              (property) =>
                                property.propertytype ===
                                category.subcategoryname
                            )
                            .map((filteredProperty) => filteredProperty.count)
                            .reduce((sum, count) => sum + count, 0)}{" "}
                          properties
                        </p>
                      </ul>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <Col xs={12} className="text-center">
                <p>No properties found</p>
              </Col>
            )}
          </Row>
        )}
      </Container>
    </>
  );
};

export default NewProducts;
