
import React from 'react'
import NewProducts from '../Component/ExploresAll/Explore'

function Explores() {
  return (
    <div>
      <NewProducts/>
    </div>
  )
}

export default Explores
