import React from 'react'
import { Link } from 'react-router-dom'

function BreadCrumbs() {
  return (
    <div>
      <nav  className=''>
    <ol class="breadcrumb p-4">
        <li class="breadcrumb-item "><Link to={"/"} className='text-decoration-none text-black '>Chennai Auction Property</Link></li>
        <li class="breadcrumb-item"><Link to={"/viewsinglepage"} className='text-decoration-none text-black'>Properties All</Link></li>
    </ol>
</nav>       
    </div>
  )
}

export default BreadCrumbs
