import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRouter = ({ IsLogin, children }) => {
  if (!IsLogin) {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default PrivateRouter;
