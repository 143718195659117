
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { API_BASE_URL } from '../utils/index';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import BootstrapCard from 'react-bootstrap/Card';
// import { IoLocationOutline } from 'react-icons/io5';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import dis7 from '../Images/Scale.png';
// import '../Style/FeaturedStyle.css';
// import Heart from 'react-heart';
// import { toast } from 'react-hot-toast';
// import Swal from 'sweetalert2';
// import { Link } from "react-router-dom";
// import Discover from "./ExploresAll/Discover";

// function Featured() {
//   const [properties, setProperties] = useState([]);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [showAllProperties, setShowAllProperties] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [likedProperties, setLikedProperties] = useState([]);

//   const formatPrice = (price) => {
//     if (price >= 10000000) {
//       return (price / 10000000).toFixed(2) + ' Cr';
//     } else if (price >= 100000) {
//       return (price / 100000).toFixed(2) + ' L';
//     } else {
//       return price.toString();
//     }
//   };

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/property/getproperty`)
//       .then((response) => {
//         const fetchedProperties = response.data.data.property;
//         if (Array.isArray(fetchedProperties)) {
//           const propertiesWithDefaultSale = fetchedProperties.map((property) => ({
//             ...property,
//             sale: property.sale || 0,
//             price: formatPrice(property.price),
//           }));

//           setProperties(propertiesWithDefaultSale);
//           setFilteredProperties(propertiesWithDefaultSale);
//           setLoading(false);
//         } else {
//           console.error('Fetched properties is not an array');
//           setProperties([]);
//           setFilteredProperties([]);
//           setLoading(false);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching properties:', error);
//         setLoading(false);
//       });
//   }, []);

//   const toggleHeart = async (_id) => {
//     try {
//       const token = localStorage.getItem('accessToken');
//       const headers = {
//         Authorization: `Bearer ${token}`,
//       };

//       if (likedProperties.some(property => property._id === _id)) {
//         await axios.put(`${API_BASE_URL}/property/dislike/${_id}`, null, { headers });
//         setLikedProperties(likedProperties.filter(property => property._id !== _id));
//         toast.success('Removed from Wishlist.');
//       } else {
//         await axios.post(`${API_BASE_URL}/property/like/${_id}`, null, { headers });
//         const likedProperty = properties.find(property => property._id === _id);
//         setLikedProperties([...likedProperties, likedProperty]);
//         toast.success('Added to Wishlist.');
//       }
//     } catch (error) {
//       console.error('Error toggling heart:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: error.response?.data.message || 'Something went wrong!',
//       });
//     }
//   };

//   const filterNewlyLaunched = () => {
//     const now = new Date();
//     const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
//     const newLaunchedProperties = properties.filter((property) => {
//       const propertyDate = new Date(property.createdAt);
//       return propertyDate >= twentyFourHoursAgo;
//     });
//     setFilteredProperties(newLaunchedProperties);
//     setShowAllProperties(false);
//   };

//   const showAll = () => {
//     setFilteredProperties(properties);
//     setShowAllProperties(true);
//   };

//   return (
//     <div className="container">
//       <Row>
//         <Col>
//           <div className="text-center">
//             <h4>Featured Properties</h4>
//             <p>
//               Discover our featured properties, showcasing the best in luxury,
//               <br />
//               comfort, and investment potential. Explore top listings and find
//               your dream home today!
//             </p>
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         <Col>
//           <div className="text-center mt-2 mb-3">
//             <button
//               className={`btn ${showAllProperties ? 'btn-outline-danger text-black' : 'btn-outline-white'}`}
//               onClick={showAll}
//             >
//               All Properties
//             </button>
//             <button
//               className={`btn ${!showAllProperties ? 'btn-outline-danger text-black' : 'btn-outline-white'}`}
//               onClick={filterNewlyLaunched}
//             >
//               Newly Launched
//             </button>
//           </div>
//         </Col>
//       </Row>
//       <div className="row">
//         {loading ? (
//           <div className="m-auto mt-5">
//             <p className="text-center">Loading...</p>
//           </div>
//         ) : filteredProperties.length > 0 ? (
//           filteredProperties.map((property) => (
//             <div className="col-lg-3 col-md-4 col-sm-4" key={property?._id}>
//               <div className="card-container mt-5">
//                 <Link to={`/viewalldetails/${property._id}`}  >
//                   <BootstrapCard.Img
//                     variant="top"
//                     src={property.propertyImages[0]?.propertyImage}
//                     className="card-media"
//                     height={'250px'}
//                     alt={`Property ${property.propertyTitle}`}
//                   />
//                 </Link>
//                 <div className="overlay-center ">
//                   <BootstrapCard.Body className=''>
//                     <Row className="justify-content-between align-items-center mt-2 fett">
//                       <Col md={8} sm={6} lg={8} className='ss'>
//                         <p className="fs-6 title-text ">{property.propertyTitle}</p>
//                       </Col>
//                       <Col md={4} sm={6} lg={4} className='ss'>
//                         <Heart
//                           isActive={likedProperties.some(likedProperty => likedProperty._id === property._id)}
//                           onClick={() => toggleHeart(property._id)}
//                           style={{ width: '1.50rem' }}
//                         />
//                       </Col>
//                     </Row>
//                     <BootstrapCard.Text className="text-start mt-2">
//                       <IoLocationOutline />
//                       {property.location}
//                     </BootstrapCard.Text>
//                   </BootstrapCard.Body >
//                   <ul className="d-flex justify-content-between p-0">
//                     <li className="text-start text-danger col-md-6">
//                       <p className="fw-bolder fs-5">₹ {property.price}</p>
//                     </li>
//                     <li className="itemssize col-md-6 mt-2">
//                       <img src={dis7} className="me-2" alt="Scale" />
//                       {property.totalArea}
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="m-auto mt-5">
//             <p className="text-center">No properties found....</p>
//           </div>
//         )}
//       </div>
//       <div className="mt-3 mb-5 justify-content-center text-center">
//         <Link to="/viewsinglepage">
//         <button className="btn btn-danger border border-radius-3">See All Listing</button>
//         </Link>
//       </div>
//       {/* Pass likedProperties state to Discover component */}
//       <Discover likedProperties={likedProperties} />
//     </div>
//   );
// }

// export default Featured;
// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { API_BASE_URL } from '../utils/index';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import BootstrapCard from 'react-bootstrap/Card';
// import { IoLocationOutline } from 'react-icons/io5';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import dis7 from '../Images/Scale.png';
// import '../Style/FeaturedStyle.css';
// import Heart from 'react-heart';
// import { toast } from 'react-hot-toast';
// import Swal from 'sweetalert2';
// import { Link } from "react-router-dom";
// import Discover from "./ExploresAll/Discover";
// import { motion } from 'framer-motion';

// function Featured() {
//   const [properties, setProperties] = useState([]);
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   const [showAllProperties, setShowAllProperties] = useState(true);
//   const [loading, setLoading] = useState(true);
//   const [likedProperties, setLikedProperties] = useState([]);

//   const formatPrice = (price) => {
//     if (price >= 10000000) {
//       return (price / 10000000).toFixed(2) + ' Cr';
//     } else if (price >= 100000) {
//       return (price / 100000).toFixed(2) + ' L';
//     } else {
//       return price.toString();
//     }
//   };

//   useEffect(() => {
//     axios
//       .get(`${API_BASE_URL}/property/getproperty`)
//       .then((response) => {
//         const fetchedProperties = response.data.data.property;
//         if (Array.isArray(fetchedProperties)) {
//           const propertiesWithDefaultSale = fetchedProperties.map((property) => ({
//             ...property,
//             sale: property.sale || 0,
//             price: formatPrice(property.price),
//           }));

//           setProperties(propertiesWithDefaultSale);
//           setFilteredProperties(propertiesWithDefaultSale);
//           setLoading(false);
//         } else {
//           console.error('Fetched properties is not an array');
//           setProperties([]);
//           setFilteredProperties([]);
//           setLoading(false);
//         }
//       })
//       .catch((error) => {
//         console.error('Error fetching properties:', error);
//         setLoading(false);
//       });
//   }, []);

//   const toggleHeart = async (_id) => {
//     try {
//       const token = localStorage.getItem('accessToken');
//       const headers = {
//         Authorization: `Bearer ${token}`,
//       };

//       if (likedProperties.some(property => property._id === _id)) {
//         await axios.put(`${API_BASE_URL}/property/dislike/${_id}`, null, { headers });
//         // setLikedProperties(likedProperties.filter(property => property._id !== _id));
//         toast.success('Removed from Wishlist.');
//       } else {
//         await axios.post(`${API_BASE_URL}/property/like/${_id}`, null, { headers });
//         const likedProperty = properties.find(property => property._id === _id);
//         // setLikedProperties([...likedProperties, likedProperty]);
//         toast.success('Added to Wishlist.');
//       }
//     } catch (error) {
//       console.error('Error toggling heart:', error);
//       Swal.fire({
//         icon: 'error',
//         title: 'Oops...',
//         text: error.response?.data.message || 'Something went wrong!',
//       });
//     }
//   };

//   const filterNewlyLaunched = () => {
//     const now = new Date();
//     const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
//     const newLaunchedProperties = properties.filter((property) => {
//       const propertyDate = new Date(property.createdAt);
//       return propertyDate >= twentyFourHoursAgo;
//     });
//     setFilteredProperties(newLaunchedProperties);
//     setShowAllProperties(false);
//   };

//   const showAll = () => {
//     setFilteredProperties(properties);
//     setShowAllProperties(true);
//   };

//   const cardVariants = {
//     hidden: { opacity: 0, y: 20 },
//     visible: { opacity: 1, y: 0 },
//   };

//   return (
//     <div className="container">
//       <Row>
//         <Col>
//           <div className="text-center">
//             <h4>Featured Properties</h4>
//             <p>
//               Discover our featured properties, showcasing the best in luxury,
//               <br />
//               comfort, and investment potential. Explore top listings and find
//               your dream home today!
//             </p>
//           </div>
//         </Col>
//       </Row>
//       <Row>
//         <Col>
//           <div className="text-center mt-2 mb-3">
//             <button
//               className={`btn ${showAllProperties ? 'btn-outline-danger text-white' : 'btn-outline-white'}`}
//               onClick={showAll}
//             >
//               All Properties
//             </button>
//             <button
//               className={`btn ${!showAllProperties ? 'btn-outline-danger text-white' : 'btn-outline-white'}`}
//               onClick={filterNewlyLaunched}
//             >
//               Newly Launched
//             </button>
//           </div>
//         </Col>
//       </Row>
//       <div className="row">
//         {loading ? (
//           <div className="m-auto mt-5">
//             <p className="text-center">Loading...</p>
//           </div>
//         ) : filteredProperties.length > 0 ? (
//           filteredProperties.map((property, index) => (
//             <motion.div
//               className="col-lg-3 col-md-4 col-sm-4"
//               key={property?._id}
//               initial="hidden"
//               animate="visible"
//               variants={cardVariants}
//               transition={{ duration: 1, delay: index * 0.1 }}
//             >
//               <div className="card-container mt-5">
//                 <Link to={`/viewalldetails/${property._id}`}  >
//                   <BootstrapCard.Img
//                     variant="top"
//                     src={property.propertyImages[0]?.propertyImage}
//                     className="card-media rounded"
//                     height={'250px'}
//                     alt={`Property ${property.propertyTitle}`}
//                   />
//                 </Link>
//                 <div className="overlay-center ">
//                   <BootstrapCard.Body className=''>
//                     <Row className="justify-content-between align-items-center mt-2 fett">
//                       <Col md={8} sm={6} lg={8} className='ss'>
//                         <p className="fs-6 title-text ">{property.propertyTitle}</p>
//                       </Col>
//                       <Col md={4} sm={6} lg={4} className='ss'>
//                         <Heart
//                           isActive={likedProperties.some(likedProperty => likedProperty._id === property._id)}
//                           onClick={() => toggleHeart(property._id)}
//                           style={{ width: '1.50rem' }}
//                         />
//                       </Col>
//                     </Row>
//                     <BootstrapCard.Text className="text-start mt-2">
//                       <IoLocationOutline />
//                       {property.location}
//                     </BootstrapCard.Text>
//                   </BootstrapCard.Body >
//                   <ul className="d-flex justify-content-between p-0">
//                     <li className="text-start text-danger col-md-6">
//                       <p className="fw-bolder fs-5">₹ {property.price}</p>
//                     </li>
//                     <li className="itemssize col-md-6 mt-2">
//                       <img src={dis7} className="me-2" alt="Scale" />
//                       {property.totalArea}
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//             </motion.div>
//           ))
//         ) : (
//           <div className="m-auto mt-5">
//             <p className="text-center">No properties found....</p>
//           </div>
//         )}
//       </div>
//       <div className="mt-3 mb-5 justify-content-center text-center">
//         <Link to="/viewsinglepage">
//           <button className="btn btn-danger border border-radius-3">See All Listing</button>
//         </Link>
//       </div>
//       {/* Pass likedProperties state to Discover component */}
//       {/* <Discover likedProperties={likedProperties} /> */}
//     </div>
//   );
// }

// export default Featured;
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../utils/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import BootstrapCard from 'react-bootstrap/Card';
import { IoLocationOutline } from 'react-icons/io5';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import dis7 from '../Images/Scale.png';
import '../Style/FeaturedStyle.css';
import Heart from 'react-heart';
import { toast } from 'react-hot-toast';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
// import axiosInstance from '../utils/429Error';

function Featured() {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [showAllProperties, setShowAllProperties] = useState(true);
  const [loading, setLoading] = useState(true);
  const [likedProperties, setLikedProperties] = useState([]);

  const formatPrice = (price) => {
    if (price >= 10000000) {
      return (price / 10000000).toFixed(2) + ' Cr';
    } else if (price >= 100000) {
      return (price / 100000).toFixed(2) + ' L';
    } else {
      return price.toString();
    }
  };

  useEffect(() => {
    axios
      .get(`${API_BASE_URL}/property/getproperty`)
      .then((response) => {
        const fetchedProperties = response.data.data.property;
        if (Array.isArray(fetchedProperties)) {
          const propertiesWithDefaultSale = fetchedProperties.map((property) => ({
            ...property,
            sale: property.sale || 0,
            price: formatPrice(property.price),
          }));

          setProperties(propertiesWithDefaultSale);
          setFilteredProperties(propertiesWithDefaultSale);
          setLoading(false);
        } else {
          console.error('Fetched properties is not an array');
          setProperties([]);
          setFilteredProperties([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching properties:', error);
        setLoading(false);
      });
  }, []);

  const toggleHeart = async (_id) => {
    try {
      const token = localStorage.getItem('accessToken');
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (likedProperties.some(property => property._id === _id)) {
        await axios.put(`${API_BASE_URL}/property/dislike/${_id}`, null, { headers });
        setLikedProperties(likedProperties.filter(property => property._id !== _id));
        toast.success('Removed from Wishlist.');
      } else {
        await  axios.post(`${API_BASE_URL}/property/like/${_id}`, null, { headers });
        const likedProperty = properties.find(property => property._id === _id);
        setLikedProperties([...likedProperties, likedProperty]);
        toast.success('Added to Wishlist.');
      }
    } catch (error) {
      console.error('Error toggling heart:', error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.response?.data.message || 'Something went wrong!',
      });
    }
  };

  const filterNewlyLaunched = () => {
    const now = new Date();
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
    const newLaunchedProperties = properties.filter((property) => {
      const propertyDate = new Date(property.createdAt);
      return propertyDate >= twentyFourHoursAgo;
    });
    setFilteredProperties(newLaunchedProperties);
    setShowAllProperties(false);
  };

  const showAll = () => {
    setFilteredProperties(properties);
    setShowAllProperties(true);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };
 
  return (
    <div className="container">
      <Row>
        <Col>
          <div className="text-center">
            <h4>Featured Properties</h4>
            <p>
              Discover our featured properties, showcasing the best in luxury,
              <br />
              comfort, and investment potential. Explore top listings and find
              your dream home today!
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center mt-2 mb-3">
            <button
              className={`btn ${showAllProperties ? 'btn-outline-danger text-black' : 'btn-outline-white'}`}
              onClick={showAll}
            >
              All Properties
            </button>
            <button
              className={`btn ${!showAllProperties ? 'btn-outline-danger text-black' : 'btn-outline-white'}`}
              onClick={filterNewlyLaunched}
            >
              Newly Launched
            </button>
          </div>
        </Col>
      </Row>
      <div className="row">
        {loading ? (
          <div className="m-auto mt-5">
            <p className="text-center">Loading...</p>
          </div>
        ) : filteredProperties.length > 0 ? (
          filteredProperties.map((property, index) => (
            <motion.div
              className="col-lg-3 col-md-6 col-sm-4"
              key={property?._id}
              initial="hidden"
              animate="visible"
              variants={cardVariants}
              transition={{ duration: 1, delay: index * 0.1 }}
            >
              <div className="card-container mt-5">
                <Link to={`/viewalldetails/${property._id}`}  >
                  <BootstrapCard.Img
                    variant="top"
                    src={property.propertyImages[0]?.propertyImage}
                    className="card-media rounded"
                    height={'250px'}
                    alt={`Property ${property.propertyTitle}`}
                  />
                </Link>
                <div className="overlay-center ">
                  <BootstrapCard.Body className=''>
                    <Row className="justify-content-between align-items-center mt-2 fett">
                      <Col md={8} sm={6} lg={8} className='ss'>
                        <p className="fs-6 title-text ">{property.propertyTitle}</p>
                      </Col>
                      <Col md={4} sm={6} lg={4} className='ss' width={"20px"}>
                        <Heart
                          isActive={likedProperties.some(likedProperty => likedProperty._id === property._id)}
                          onClick={() => toggleHeart(property._id)}
                          style={{ width: '1.50rem' }}
                        />
                      </Col>
                    </Row>
                    <BootstrapCard.Text className="text-start mt-2">
                      <IoLocationOutline />
                      {property.location}
                    </BootstrapCard.Text>
                  </BootstrapCard.Body >
                  <ul className="d-flex justify-content-between p-0">
                    <li className="text-start text-danger col-md-6">
                      <span className="fw-bolder fs-5">₹ {property.price}</span>
                    </li>
                    <li className="itemssize col-md-6 ">
                      <img src={dis7} className="me-2" alt="Scale" />
                      {property.totalArea} <sapn className="fw-light">
                        
                        sqft
                        </sapn>
                    </li>
                  </ul>
                </div>
              </div>
            </motion.div>
          ))
        ) : (
          <div className="m-auto mt-5">
            <p className="text-center">No properties found....</p>
          </div>
        )}
      </div>
      <div className="mt-3 mb-5 justify-content-center text-center">
        <Link to="/viewsinglepage">
          <button className="btn btn-danger rounded-pill">See All Listing</button>
        </Link>
      </div>
    </div>
  );
}

export default Featured;
