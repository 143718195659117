
// import axios from 'axios';
// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { API_BASE_URL } from '../../utils';
// import ImageGallery from 'react-image-gallery';
// import { IoIosPerson } from 'react-icons/io';
// import { AiOutlineHome } from 'react-icons/ai';
// import { ImLocation2 } from "react-icons/im";
// import ReactWhatsapp from 'react-whatsapp';

// function ViewAllDetails() {
//   const { _id } = useParams(); // Capture the '_id' parameter from the URL
//   const [propertyDetails, setPropertyDetails] = useState(null);

//   useEffect(() => {
//     if (_id) {
//       axios.post(`${API_BASE_URL}/property/${_id}`) // Using GET instead of POST to fetch property details
//         .then((res) => {
//           setPropertyDetails(res.data.data); // Assuming your API response structure wraps data in 'data'
//           console.log(res.data);
//         })
//         .catch((error) => {
//           console.error("There was an error making the request:", error);
//         });
//     }
//   }, [_id]);

//   if (!propertyDetails) {
//     return <div>Loading...</div>; // Loading state while waiting for propertyDetails to be fetched
//   }

//   // Prepare images for ImageGallery
//   const images = propertyDetails.propertyImages.map((image, index) => ({
//     original: image.propertyImage,
//     thumbnail: image.propertyImage,
//     originalAlt: `Image ${index + 1}`,
//     thumbnailAlt: `Thumbnail ${index + 1}`,
//   }));

//   // Format price function
//   const formatPrice = (price) => {
//     if (!price) return ''; // Handle edge cases where price might be null or undefined

//     if (price >= 10000000) {
//       return (price / 10000000).toFixed(2) + ' Cr';
//     } else if (price >= 100000) {
//       return (price / 100000).toFixed(2) + ' L';
//     } else {
//       return price.toString();
//     }
//   };

//   return (
//     <>
//       {propertyDetails.propertyImages && propertyDetails.propertyImages.length > 0 && (
//         <img src={propertyDetails.propertyImages[0].propertyImage} alt="Property" className='viewall-detailspage' />
//       )}

//       <div className='container mt-5'>
//         <div className='box-range breadcolors text-white'>
//           <div>
//             <div className='d-flex'>
//               <h4 className=''>{propertyDetails.propertyTitle}</h4>
//               <h4 className='ms-auto mt-2'><i className="fa-solid fa-indian-rupee-sign"></i> {
//                 formatPrice(propertyDetails.price)}
//               </h4>
//             </div>
//             <h4>   
//             {propertyDetails.location}
//             </h4>          </div>
//           <div>
           
//           </div>
//         </div>

//         <div className='row mt-5'>
//           <div className='col-md-6'>
//             {propertyDetails.propertyImages && (
//               <ImageGallery items={images} />
//             )}
//           </div>

//           <div className="col-md-6 sha">
//             <h5 className='fw-light text-center mb-5'>Essential Information properties</h5>
//             <div className="card-body">
//               <div className='row'>
//                 <div className="col-md-6 ">
//                   <div className='ms-4'>

//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Property Title</span><br />
//                     {propertyDetails.propertyTitle}
//                   </p>
//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Type</span><br />
//                     {propertyDetails.type}
//                   </p>
//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Property Type</span><br />
//                     {propertyDetails.propertyType}
//                   </p>
//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Address</span><br />
//                     {propertyDetails.address}
//                   </p>
//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Property Facing</span><br />
//                     {propertyDetails.propertyFacing}
//                   </p>
//                   </div>
//                 </div>
//                 <div className='col-md-6'>
//                   <div className='ms-2'>


//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Location</span><br />
//                     {propertyDetails.location}
//                   </p>
//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Price</span><br />
//                     {formatPrice(propertyDetails.price)}
//                   </p>
//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                     <span className="fw-semibold">Total Area</span><br />
//                     {propertyDetails.totalArea}Sqft
//                   </p>
//                   <p className="fw-light" style={{ fontSize: "16px" }}>
//                   <ReactWhatsapp number="7397589498" 
//                    message={`Hello, I'm interested in your property: ${propertyDetails.propertyTitle} `}
//                       element="button"
//                   />
//                   </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className='row mt-5'>
//           <div className='col card'>
//             <div className='card-body'>
//               <h5> Description </h5>
//               <div dangerouslySetInnerHTML={{ __html: propertyDetails.propertyDescription }} />
//             </div>
//           </div>
//         </div>

//         <div className='row mt-5'>
//           <div className='col'>
//             <iframe
//               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31112.190809174856!2d80.11981764993156!3d12.906187825639721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525f21cdd8bea1%3A0xcf81fa57f8b5d072!2sSelaiyur%2C%20Chennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1720704685790!5m2!1sen!2sin"
//               allowFullScreen=""
//               loading="lazy"
//               referrerPolicy="no-referrer-when-downgrade"
//               className='w-100'
//             />
//           </div>
//         </div>

//       </div>
//     </>
//   );
// }

// export default ViewAllDetails;


import { AiFillHdd } from "react-icons/ai";
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_BASE_URL } from '../../utils';
import ImageGallery from 'react-image-gallery';
import ReactWhatsapp from 'react-whatsapp';
import { BsBuilding } from "react-icons/bs";
import { MdAddHomeWork } from "react-icons/md";
import { MdApartment } from "react-icons/md";
import { LiaAddressCardSolid } from "react-icons/lia";
import { TbNorthStar } from "react-icons/tb";
import { SlLocationPin } from "react-icons/sl";
import { FaIndianRupeeSign } from "react-icons/fa6";

import EnquiryFormModal from "../Enquiry/Enquiry";
function ViewAllDetails() {
  const { _id } = useParams(); // Capture the '_id' parameter from the URL
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [modalShow, setModalShow] = useState(false);
   useEffect(() => {
    if (_id) {
      axios.post(`${API_BASE_URL}/property/${_id}`) // Using GET instead of POST to fetch property details
        .then((res) => {
          setPropertyDetails(res.data.data); // Assuming your API response structure wraps data in 'data'
          console.log(res.data);
        })
        .catch((error) => {
          console.error("There was an error making the request:", error);
        });
    }
  }, [_id]);


  if (!propertyDetails) {
    return <div>Loading...</div>; // Loading state while waiting for propertyDetails to be fetched
  }

  // Prepare images for ImageGallery
  const images = propertyDetails.propertyImages?.map((image, index) => ({
    original: image.propertyImage,
    thumbnail: image.propertyImage,
    originalAlt: `Image ${index + 1}`,
    thumbnailAlt: `Thumbnail ${index + 1}`,
  })) || [];

  // Format price function
  const formatPrice = (price) => {
    if (!price) return ''; // Handle edge cases where price might be null or undefined

    if (price >= 10000000) {
      return (price / 10000000).toFixed(2) + ' Cr';
    } else if (price >= 100000) {
      return (price / 100000).toFixed(2) + ' L';
    } else {
      return price.toString();
    }
  };

  // Function to strip HTML tags
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const whatsappMessage = `*Hello, I am interested in the following property:*

*Title*: ${propertyDetails.propertyTitle}
*Type*: ${propertyDetails.type}
*Property Type*: ${propertyDetails.propertyType}
*Address*: ${propertyDetails.address}
*Property Facing*: ${propertyDetails.propertyFacing}
*Location*: ${propertyDetails.location}
*Price*: ${formatPrice(propertyDetails.price)}
*Total Area*: ${propertyDetails.totalArea} Sqft
*Description*: ${stripHtmlTags(propertyDetails.propertyDescription)}


*Please provide me with more information. Thank you!*
`;

  return (
    <>
      {propertyDetails.propertyImages && propertyDetails.propertyImages.length > 0 && (
        <img src={propertyDetails.propertyImages[0].propertyImage} alt="Property" className='viewall-detailspage' />
      )}

      <div className='container mt-5'>
        <div className='box-range breadcolors text-white'>
          <div>
            <div className='d-flex'>
              <h4 className=''>{propertyDetails.propertyTitle}</h4>
              <h4 className='ms-auto mt-2'><i className="fa-solid fa-indian-rupee-sign"></i> {
                formatPrice(propertyDetails.price)}
              </h4>
            </div>
            <h4 className="d-flex">   
              {propertyDetails.location}
         
        
            </h4>

          </div>
        </div>

        <div className='row mt-5'>
          <div className='col-md-6'>
            {propertyDetails.propertyImages && (
              <div className=''>
                <ImageGallery items={images} />
              </div>
            )}
          </div>

          <div className="col-md-6 sha">
            <h5 className='fw-light text-center mb-5'>Essential Information properties</h5>
            <div className="card-body">
              <div className='row'>
                <div className="col-md-6 dsfscsdsd ">
                  <div className='ms-4'>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><BsBuilding className="fs-2 border border-1 p-1 me-2 border-black text-danger "/></span>
                    <span className="fw-normal">  Property Title</span><br />
                   <sapn style={{ fontSize: "14px" }} className="ms-5"> {propertyDetails.propertyTitle}
                    </sapn>
                  </p>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><MdAddHomeWork className="fs-2 border border-1 p-1 me-2 border-black text-danger "/></span>
                    <span className="fw-normal"> Type</span><br />
                    <span style={{ fontSize: "14px" }} className="ms-5">

                    {propertyDetails.type}
                    </span>
                  </p>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><MdApartment className="fs-2 border border-1 p-1 me-2 border-black text-danger "/></span>
                    <span className="fw-normal">   Property Type</span><br />
                    <span style={{ fontSize: "14px" }} className="ms-5">

                    {propertyDetails.propertyType}
                    </span>
                  </p>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><LiaAddressCardSolid className="fs-2 border border-1 p-1 me-2 border-black text-danger" /></span>
                    <span className="fw-normal"> Address</span><br />
                    <span style={{ fontSize: "14px" }} className="ms-5 ">

                    {propertyDetails.address}
                    </span>
                  </p>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><TbNorthStar className="fs-2 border border-1 p-1 me-2 border-black text-danger "/></span>
                    <span className="fw-normal"> Property Facing</span><br />
                    <span style={{ fontSize: "14px" }} className="ms-5">

                    {propertyDetails.propertyFacing}
                    </span>
                  </p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='ms-4'>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><SlLocationPin className="fs-2 border border-1 p-1 me-2 border-black text-danger "/></span>
                    <span className="fw-normal"> Location</span><br />
                    <span style={{ fontSize: "14px" }} className="ms-5">

                    {propertyDetails.location}
                    </span>

                  </p>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><FaIndianRupeeSign className="fs-2 border border-1 p-1 me-2 border-black text-danger " /></span>
                    <span className="fw-normal">Price</span><br />
                    <span style={{ fontSize: "14px" }} className="ms-5">

                    {formatPrice(propertyDetails.price)}
                    </span>
                  </p>
                  <p className="fw-light" style={{ fontSize: "16px" }}>
                    <span><AiFillHdd className="fs-2 border border-1 p-1 me-2 border-black text-danger "/></span>
                    <span className="fw-normal">Total Area</span><br />
                    <span style={{ fontSize: "14px" }} className="ms-5">

                    {propertyDetails.totalArea} Sqft
                    </span>
                  </p>
                  {/* <p className="text-center">Contact Us</p>
                  <div className="d-flex">
                  <p className=" btn  bg-white " style={{ fontSize: "16px" }}>
                   
                    <ReactWhatsapp 
                      number={propertyDetails.contactNumber || "7397589498"} 
                      message={whatsappMessage}
                      className='border-0 '
                      style={{background:"transparent",color:"#67dc18"
                      }}
                    >
                      
                    <i className="fa-brands fa-whatsapp fs-2" style={{color:" #67dc18;"}}></i> <span className="mb-5">
                      
                      
                      </span>
                    </ReactWhatsapp>
                  </p>
                  <button className='btn btn-outline-dark ms-5 fw-bold' onClick={() => setModalShow(true)}>Enquiry</button>
  <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

<div className=" mt-3 text-end ">
  {/* <h5 className="ms-3 text-center">CONTACT </h5> */}
 <div className="">

  <p className=" btn btn-outline-success bg-white mt-3 " style={{ fontSize: "16px" }}>
                   
                    <ReactWhatsapp 
                      number={propertyDetails.contactNumber || "7397589498"} 
                      message={whatsappMessage}
                      className='border-0 '
                      style={{background:"transparent",color:"#67dc18"
                      }}
                    >
                      
                    <i className="fa-brands fa-whatsapp fs-5" style={{color:" #67dc18;"}}></i> <span className="">
                      
                      Contact Us
                      </span>
                    </ReactWhatsapp>
                  </p>

  <button className='btn btn-outline-dark text-right fw-bold'style={{ fontSize: "16px" }} onClick={() => setModalShow(true)}>Enquiry</button>
  <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
 </div>

</div>
        <div className='row mt-5'>
          <div className='col card' style={{height:"auto"}}>
            <div className='card-body' >
              <h5>Description</h5>
              <div dangerouslySetInnerHTML={{ __html: propertyDetails.propertyDescription }} />
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31112.190809174856!2d80.11981764993156!3d12.906187825639721!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525f21cdd8bea1%3A0xcf81fa57f8b5d072!2sSelaiyur%2C%20Chennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1720704685790!5m2!1sen!2sin"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className='w-100'
              title="Property Location"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewAllDetails;
