
  import React, { useEffect, useState } from 'react';
  import Container from 'react-bootstrap/Container';
  import Row from 'react-bootstrap/Row';
  import Col from 'react-bootstrap/Col';
  import DropdownButton from 'react-bootstrap/DropdownButton';
  import Dropdown from 'react-bootstrap/Dropdown';
  import { FaHome, FaBuilding } from 'react-icons/fa';
  import axios from 'axios';
  import '../Style/PriceRangeStyle.css';
  import { useNavigate } from 'react-router-dom';
  import Slider from 'rc-slider';
  import 'rc-slider/assets/index.css';
  import { API_BASE_URL } from '../utils';
  import { useAuthHeaders } from './Tokenrefresh';


  function PriceRange() {
    const [categories, setCategories] = useState([]);
    const [type, setType] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [priceRange, setPriceRange] = useState([100000, 10000000]);
    const headers = useAuthHeaders();
    const navigate = useNavigate();
    // const [propertyCount, setPropertyCount] = useState(0);
    useEffect(() => {
      axios
        .get(`${API_BASE_URL}/getallcategory`, { headers })
        .then((response) => {
          const categoriesData = response?.data?.data;
          setCategories(categoriesData);
        })
        .catch((error) => {
          console.error("Error fetching categories:", error);
        });
    }, [headers]);

    useEffect(() => {
      if (type) {
        axios
          .get(`${API_BASE_URL}/getsubcategorybycategory/${type}`, { headers })
          .then((response) => {
            setSubCategories(response.data.data);
          })
          .catch((error) => {
            console.error("Error fetching subcategory data:", error);
          });
      }
    }, [type, headers]);

    const handleCategorySelect = (eventKey) => {
      setType(eventKey);
      setSelectedSubCategory('');
    };

    const handleSubCategorySelect = (eventKey) => {
      setSelectedSubCategory(eventKey);
    };

    // const handleSearch = () => {
    //   const selectedCategoryName = categories.find(cat => cat._id === type)?.categoryname || '';
    //   const selectedSubCategoryName = subCategories.find(sub => sub._id === selectedSubCategory)?.subcategoryname || '';
    //   const [minPrice, maxPrice] = priceRange;

    //   axios
    //     .get(`${API_BASE_URL}/property/getproperty?keyword=${searchInput}&type=${selectedCategoryName}&propertyType=${selectedSubCategoryName}&price[gte]=${minPrice}&price[lte]=${maxPrice}`, { headers })

    //     .then((response) => {
    //       const propertiesData = response?.data?.data?.properties;
        
    //       if (Array.isArray(propertiesData)) {
    //         setPropertyCount(propertiesData.length);
    //         navigate("/filterpageview", { state: { filteredProperties: propertiesData } });
    //       } else {
    //         console.error('API response is not an array:', response.data);
    //         setPropertyCount(0);
    //       }
    //     })
    //     .catch((error) => {
    //       console.error('Error fetching property:', error);
    //       setPropertyCount(0);
    //     });
    // };
    const handleSearch = () => {
      const selectedCategoryName = categories.find(cat => cat._id === type)?.categoryname || '';
      const selectedSubCategoryName = subCategories.find(sub => sub._id === selectedSubCategory)?.subcategoryname || '';
      const [minPrice, maxPrice] = priceRange;
    
      axios
        .get(`${API_BASE_URL}/property/getproperty?keyword=${searchInput}&type=${selectedCategoryName}&propertyType=${selectedSubCategoryName}&price[gte]=${minPrice}&price[lte]=${maxPrice}`, { headers })
        .then((response) => {
          const propertiesData = response?.data?.data?.properties;
    
          if (Array.isArray(propertiesData)) {
            // setPropertyCount(propertiesData.length);
            navigate("/filterheader", { state: { filteredProperties: propertiesData } });
          } else {
            console.error('API response is not an array:', response.data);
            // setPropertyCount(0);
          }
        })
        .catch((error) => {
          console.error('Error fetching property:', error);
          // setPropertyCount(0);
        });
    };
    
    const formatPrice = (price) => {
      if (price >= 10000000) {
        return (price / 10000000).toFixed(2) + ' Cr';
      } else if (price >= 100000) {
        return (price / 100000).toFixed(2) + ' L';
      } else {
        return price.toString();
      }
    };

    return (
      <Container>
        <div className="box-range breadcolorss">
          <Row className="justify-content-center ">
            <Col  md={3} lg={4} className="mb-3">
              <DropdownButton 
              variant='black'
                id="dropdown-basic-button-1"
                title={<span><FaBuilding style={{ marginRight: '8px', color: "white" }} /> {type ? categories.find(cat => cat._id === type)?.categoryname : "Select type"}</span>}
                className="dropdown-button  "
                onSelect={handleCategorySelect}
              >
                {categories.map((category) => (
                  <Dropdown.Item eventKey={category._id} key={category._id} > 
                    {category.categoryname}
                  </Dropdown.Item>  
                ))}
              </DropdownButton>
              <div className='mt-3 '> 
                <DropdownButton
                 variant='black'
                  id="dropdown-basic-button-2"
                  title={<span><FaHome style={{ marginRight: '2px', color: "white" }} /> {selectedSubCategory ? subCategories.find(sub => sub._id === selectedSubCategory)?.subcategoryname : "Select Property"}</span>}
                  className="dropdown-button   ssss"
                  onSelect={handleSubCategorySelect}
                  disabled={!type}
                >
                  {subCategories.map((sub) => (
                    <Dropdown.Item eventKey={sub._id} key={sub._id}  >
                      {sub.subcategoryname}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </div>
            </Col>
            <Col xs={12} sm={6} md={6} className="mb-3">
              <input
                type='text'
                placeholder='Enter Location or Project'
                className='form-control form-control-1'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <Slider
                range
                min={0}
                max={10000000}
                step={50000}
                defaultValue={priceRange}
                onChange={(value) => setPriceRange(value)}
                className="w-100 mt-4"
              />
              <div className="d-flex justify-content-between ">
                <p className='text-white'>Selected Price Range: ₹{formatPrice(priceRange[0])} - ₹{formatPrice(priceRange[1])}</p>
              </div>
            </Col>
            <Col xs={12} sm={6} md={2} className=" ">
              <hr className="vertical-hr" />
              <div className="text-center">
             
                {/* <h4>{propertyCount} </h4> */}
                <button className="btn btn-danger btn-search mt-5" onClick={handleSearch}>Search</button>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }

  export default PriceRange;
