
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './Component/Tokenrefresh';
import userContext from './Component/UseContext';
import Layout from './Layout';
import Home from './Rotues/Home';
import Explores from './Rotues/Explores';
import LikedProperties from './Component/ExploresAll/Discover';
import FindPropertie from './Rotues/FindPropertie';
import Featured from './Component/Featured';
import Register from './Component/Registers'
import About from './Rotues/About';
import Customer from './Rotues/Customer';
import ViewSinglePage from './Component/Properties/ViewSinglePage';
import FilterpageView from './Component/Filterpages/FilterpageView';
import ViewAllDetails from './Component/Properties/ViewAllDetails';
import EnquiryFormModal from './Component/Enquiry/Enquiry';
import Appartment from './Component/SubPropeties/Appartment';
import IndividualVilla from './Component/SubPropeties/IndividualVilla';
import LandPlots from './Component/SubPropeties/LandPlots';
import Blog from './Component/SubPropeties/Blog';
import FilterHeader from './Component/Filterpages/FilterHeader';
import MyProfile from './Component/MyProfiles';
import PrivateRouter from './Component/PrivateRouter';
// import { Rotate90DegreesCcw } from '@mui/icons-material';
import Discover from './Component/ExploresAll/Discover';
// import { Switch } from 'antd';

function App() {
  const [IsLogin, setIsLogOut] = useState(false);

  const signIn = () => {
    setIsLogOut(true);
  };

  const signOut = () => {
    setIsLogOut(false);
  };

  return (
    <div className="App">
      <userContext.Provider value={{ IsLogin, signIn, signOut }}>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/explores/:id" element={<Explores />} />
                <Route path="/liked-properties" element={<LikedProperties />} />
                <Route path="/findproperties" element={<FindPropertie />} />
                <Route path="/featured" element={<Featured />} />
                <Route path="/registers" element={<Register />} />
                <Route path="/about" element={<About />} />
                <Route path="/customer" element={<Customer />} />
                <Route path="/viewsinglepage" element={<ViewSinglePage />} />
                <Route path="/filterpageview" element={<FilterpageView />} />
                <Route path="/viewalldetails/:_id" element={<ViewAllDetails />} />
                <Route path="/enquiryformmodaly/:_id" element={<EnquiryFormModal />} />
                <Route path="/filterheader" element={<FilterHeader />} />

             

                <Route path="/appartments" element={<Appartment />} />
                <Route path="/individual villa" element={<IndividualVilla />} />
                <Route path="/land/plots" element={<LandPlots />} />
                <Route path="/blog" element={<Blog />} />
              


                <Route path="/Myprofiles" element={<MyProfile />} />
                {/* Protected Routes */}
                <Route path="*" element={<PrivateRouter />} />
                <Route path="/discover" element={<Discover/>}></Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </userContext.Provider>
    </div>
  );
}

export default App;
