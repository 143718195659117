

import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaHome, FaBuilding } from 'react-icons/fa';
// import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import axios from 'axios';
import "../Filterpages/search.css";
import { useAuthHeaders } from "../../Component/Tokenrefresh";
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../utils';
// import Enquiry from "../Enquiry/Enquiry"
// import PropertyFilter from './FilterComponent';
// import EnquiryFormModal from '../Enquiry/Enquiry';
// import FilterContent from './FilterContent';

function Filterpageproperty() {
    // const [propertyCount, setPropertyCount] = useState(0);
    const [searchInput, setSearchInput] = useState('');
    // const [filteredProperties, setFilteredProperties] = useState([]);
    const [categories, setCategories] = useState([]);
    const [type, setType] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    // const [priceRange, setPriceRange] = useState([100000, 10000000]);
    const headers = useAuthHeaders();
    const navigate = useNavigate();
    // const location = useLocation();
    
    // const filteredPropertiesFromState = location.state?.filteredProperties || [];
    // const [expandedDescriptions, setExpandedDescriptions] = useState({});
    // const [modalShow, setModalShow] = React.useState(false);
    useEffect(() => {
        axios.get(`${API_BASE_URL}/getallcategory`, { headers })
            .then((response) => {
                const categoriesData = response?.data?.data;
                setCategories(categoriesData);
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    }, [headers]);

    useEffect(() => {
        if (type) {
            axios.get(`${API_BASE_URL}/getsubcategorybycategory/${type}`, { headers })
                .then((response) => {
                    setSubCategories(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching subcategory data:", error);
                });
        }
    }, [type, headers]);

    useEffect(() => {
        // setFilteredProperties(filteredPropertiesFromState);
        // setPropertyCount(filteredPropertiesFromState?.length);
    // }, [filteredPropertiesFromState]);
    }, []);

    const handleSearch = () => {
        const selectedCategoryName = categories.find(cat => cat._id === type)?.categoryname || '';
        const selectedSubCategoryName = subCategories.find(sub => sub._id === selectedSubCategory)?.subcategoryname || '';


        axios.get(`${API_BASE_URL}/property/getproperty?keyword=${searchInput}&type=${selectedCategoryName}&propertyType=${selectedSubCategoryName}`, { headers })
            .then((response) => {
                const propertiesData = response?.data?.data?.properties;
                if (Array.isArray(propertiesData)) {
                    // setFilteredProperties(propertiesData);
                    // setPropertyCount(propertiesData.length);
                    navigate('/filterheader', { state: { filteredProperties: propertiesData } });
                } else {
                    console.error('API response is not an array:', response.data);
                    // setPropertyCount(0);
                }
            })
            .catch((error) => {
                console.error('Error fetching properties:', error);
                // setPropertyCount(0);
            });
    };

    const handleCategorySelect = (eventKey) => {
        setType(eventKey);
        setSelectedSubCategory('');
    };

    const handleSubCategorySelect = (eventKey) => {
        setSelectedSubCategory(eventKey);
    };

    // const formatPrice = (price) => {
    //     if (price >= 10000000) {
    //         return (price / 10000000).toFixed(2) + ' Cr';
    //     } else if (price >= 100000) {
    //         return (price / 100000).toFixed(2) + ' L';
    //     } else {
    //         return price.toString();
    //     }
    // };
    // const toggleDescription = (index) => {
    //     setExpandedDescriptions(prevState => ({
    //         ...prevState,
    //         [index]: !prevState[index]
    //     }));
    // };
    // const handleLearnMoreClick = (propertyId) => {
    //     navigate(`/property/${propertyId}`);
    // };

    return (
        <Container>
            <div className="mt-5 sha breadcolors">
                <Row className="justify-content-center">
                    <Col xs={12} sm={6} md={2} className="text-left">
                        <DropdownButton
                            id="dropdown-basic-button-7"
                            title={<span><FaBuilding style={{ marginRight: '', color: "#EB6753" }} /> {type ? categories.find(cat => cat._id === type)?.categoryname : "Select type"}</span>}
                            className="dropdown-button   "
                            onSelect={handleCategorySelect}
                            
                        >
                            {categories.map((category) => (
                                <Dropdown.Item eventKey={category._id} key={category._id} >
                                    {category.categoryname}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>
                    </Col>
                    <Col xs={12} sm={6} md={2} className=''>
                        <div className=''>
                            <DropdownButton
                                id="dropdown-basic-button-6"
                                title={<span><FaHome style={{ marginRight: '', color: "#EB6753" }} /> {selectedSubCategory ? subCategories.find(sub => sub._id === selectedSubCategory)?.subcategoryname : "Select Property"}</span>}
                                className="dropdown-button ms-2  "
                                onSelect={handleSubCategorySelect}
                                disabled={!type}
                            >
                                {subCategories.map((sub) => (
                                    <Dropdown.Item eventKey={sub._id} key={sub._id} >
                                        {sub.subcategoryname}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                    </Col>
                    <Col xs={12} sm={6} md={6} className="">
                        <input
                            type='text'
                            placeholder='Enter Location or Project'
                            className='form-control form-control-1 ms-4'
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            style={{ height: "50px" }}
                        />

                    </Col>
                    <Col xs={12} sm={6} md={2} className=" ">

                        <div className="text-center ">
                            <button className="btn btn-danger btn-search me-5" style={{ height: "50px" }} onClick={handleSearch}>Search</button>
                        </div>
                    </Col>
                </Row>
            </div>

           
        </Container>
    );
}

export default Filterpageproperty;
