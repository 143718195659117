import React from 'react'
// import  Navbar  from '../Headerss/Navbar'
import MainHeader from './MainHeader'

function Header() {
  return (
    <div className='Navbar-2 p-3 bg-black position-sticky text-white'>
    
    <MainHeader />

   
      {/* <Navbar/> */}
    
    </div>
  )
}

export default Header
