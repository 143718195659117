import React from 'react'
import FindProperties from '../Component/FindProperties'
function FindPropertie() {
  return (
    <div>
      <FindProperties></FindProperties>
    </div>
  )
}

export default FindPropertie
