  // import "../Style/HeroimageStyle.css"
  // import React from 'react'
  // import 'bootstrap/dist/css/bootstrap.min.css';

  // function Heroimage() {
  //   return (
  //     <>
  //       <div className="hero-image">


  //         <div className="contains p-2">

  //           <div className="hero-content text-center ">
              
  //           <h3 className="conten-h3 ">Find Your Perfect Abode with<br/><span className="text-danger">
  //           Chennai's Premier Property Listings
  //             </span> 
  //             </h3>
  //             <p>Explore Chennai's Finest Properties Here!</p>
  //           </div>
  //         </div>
  //       </div>
        
  //     </>
  //   )
  // }

  // export default Heroimage
  import React, { useEffect } from 'react';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import ScrollMagic from 'scrollmagic';
  
  import "../Style/HeroimageStyle.css";
  
  function Heroimage() {
    useEffect(() => {
      // Initialize ScrollMagic controller
      const controller = new ScrollMagic.Controller();
  
      // Create a scene
      new ScrollMagic.Scene({
        duration: 100, // the scene should last for a scroll distance of 100px
        offset: 50 // start this scene after scrolling for 50px
      })
      .setPin("#my-sticky-element") // pins the element for the scene's duration
      .addTo(controller); // assign the scene to the controller
  
      // Clean up on component unmount
      return () => {
        controller.destroy();
      };
    }, []);
  
    return (
      <>
        <div className="hero-image">
          <div className="contains p-2">
            <div className="hero-content text-center">
              <h3 className="conten-h3">
                Find Your Perfect Abode with<br/>
                <span className="text-danger">Chennai's Premier Property Listings</span>
              </h3>
              <p>Explore Chennai's Finest Properties Here!</p>
            </div>
          </div>
        </div>
      </>
    );
  }
  
  export default Heroimage;
  