import React from "react";

import Footer from "./Component/Footer";
import { Outlet } from 'react-router-dom'
import Header from "./Component/Headerss/Header";
import About from "./Component/About";


function Layout() {
  return (
    <>
      <Header/>
      <Outlet />
      <About/>
      <Footer/>
    </>
  )
}

export default Layout
