
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import "../Style/LoginStyle.css";
import { FaEye, FaEyeSlash,  FaApple, FaFacebookSquare } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../utils';
import Swal from 'sweetalert2';
import { MdOutlineAlternateEmail } from "react-icons/md";
import ForgotPasswordModal from "../Component/ForgotPasswordModal"
import { FcGoogle } from "react-icons/fc";

function LoginModal({ show, handleClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const navigate = useNavigate();
  // const [showErrorToast, setShowErrorToast] = useState(false);
  const token = localStorage.getItem("accessToken");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    setLoading(true);

    axios
      .post(
        `${API_BASE_URL}/users/login`,
        { email, password },
        { headers }
      )
      .then((result) => {
        Swal.fire({
          text: "Login Successful",
          icon: "success"
        });

        const accessToken = result.data.data.accessToken;
        const refreshToken = result.data.data.refreshToken;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        handleClose();
        navigate("/");
      })
      .catch((error) => {
        console.error("Error in API call:", error);
        // setShowErrorToast(true);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };

  const handleShowForgotPasswordModal = () => {
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <div className='x'>
          <Modal.Header closeButton>
            
          </Modal.Header>
          <Form onSubmit={handleLogin}>
          {/* <Modal.Title className='text-center'> */}
           <span className='fs-5 m-2' style={{justifyContent:"center", display:"flex",marginBottom:"12px"}}> Login  Chennai Auction Property  </span>   
              {/* <h5 className='text-center ms-5'>Chennai Auction Property</h5> */}
            {/* </Modal.Title> */}
            <Form.Group controlId="formEmail mt-2">
              <Form.Label className='mx-5'>Email address</Form.Label>
              <div className='boxs-1'>
                <input
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  className='box-input1'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <MdOutlineAlternateEmail className="text-indent" id="btnicon" />
              </div>
            </Form.Group>
            <Form.Group controlId="formPassword" className="mt-3">
              <Form.Label className='mx-5'>Password</Form.Label>
              <div className='boxs-1'>
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='box-input1'
                />
                <button onClick={togglePasswordVisibility} id="btnicon" type="button">
                  {passwordShown ? (
                    <FaEye className="passwordbtn" />
                  ) : (
                    <FaEyeSlash className="passwordbtn" />
                  )}
                </button>
              </div>
              <div className="check-box mt-3">
                <ul className="d-flex">
                  <li><input type="checkbox" className="mx-1" />Remember me </li>
                  <li className=""><Button variant="link" onClick={handleShowForgotPasswordModal} style={{ marginLeft: "110px" }}>Forgot Password ?</Button></li>
                </ul>
              </div>
            </Form.Group>
            <Button variant="primary" type="submit" className='login-btn ms-4'>
              Login
            </Button>
          </Form>
          <p className='text-center'> OR USE</p>
          <ul className='d-flex me-4' style={{ justifyContent: 'center' }}>
            <li className='fs-2 p-1'><FcGoogle /></li>
            <li className='fs-2 p-1'><FaApple /></li>
            <li className='fs-2 p-1'><FaFacebookSquare /></li>
          </ul>
          <div className="text-center mt-2 ms-4">
            <Link to="/register">Register a new membership</Link>
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      <ForgotPasswordModal show={showForgotPasswordModal} handleClose={handleCloseForgotPasswordModal} />
    </>
  );
}

export default LoginModal;
