// import "../Style/WinningStyle.css";
// import React from 'react';
// import Row from "react-bootstrap/esm/Row";
// import Col from "react-bootstrap/esm/Col";
// import Container from "react-bootstrap/esm/Container";
// import winning1 from "../Images/winningimg1.png";
// import winning2 from "../Images/winningimg2.png";
// import win from "../Images/FunFact.png";
// import win1 from "../Images/Win1.png";
// function Winning() {
//   return (
//     <Container className="">
//       <Row>
//         <Col className="mt-5 col-lg-5 col-md-6 col-12">
//           <div>
//             <h3>Why Choose Us?</h3>
//             <p>Trust Chennai Auction Property for Your Next Winning Bid.</p>
//           </div>
//           <ul >
//             <li className=""><div className="d-flex">

//             <img src={win1} className="me-3"/> <h5>Excellent Communication</h5>
//             </div>
//               <p className="ms-5">The single biggest problem in communication is<br></br> the illusion that it has taken place.</p>
//             </li>
//             <li><div className="d-flex">

//             <img src={win1} className="me-3"/>
//               <h5>Extensive Market Knowledge</h5>
//             </div>
//               <p className="ms-5">In the world of real estate, knowledge is key. <br></br>Without it, you are at the mercy of those who have it.</p>
//             </li>
//             <li>
//               <div className="d-flex">
//             <img src={win1} className="me-3"/>

//               <h5>Strong Negotiators</h5>
//               </div>
//               <p className="ms-5">A successful negotiation is one where both parties <br></br>feel they've achieved something valuable.</p>
//             </li>
//             <li>
//               <button className="btn btn-outline-danger text-black mt-5">Learn More</button>
//             </li>
//           </ul>
//         </Col>
//         <Col className="text-white col-lg-7 col-md-6 col-12 mt-5 mb-3">
//           <div>
//             <div className="d-flex flex-column flex-md-row">
//               <div className="  ">
//                 <div className=" box-winning ">
//                   <img src={win} className="  " />
//                 </div>
//               </div>
//               <div>
//                 <img src={winning1} className="winningimg-1 ms-0 mb-3  ms-md-4 img-fluid" alt="Winning 1" />
//               </div>
//             </div >
//             <img src={winning2} className="winningimg-2 img-fluid  mt-md-0" alt="Winning 2" />
//           </div>
//         </Col>
//       </Row>
//     </Container>
//   );
// }

// export default Winning;
import "../Style/WinningStyle.css";
import React from 'react';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import winning1 from "../Images/winningimg1.png";
import winning2 from "../Images/winningimg2.png";
import win from "../Images/FunFact.png";
import win1 from "../Images/Win1.png";

function Winning() {
  return (
    <Container className="winning-container">
      <Row>
        <Col className="mt-5 col-lg-5 col-md-6 col-12">
          <div className="fade-in">
            <h3>Why Choose Us?</h3>
            <p>Trust Chennai Auction Property for Your Next Winning Bid.</p>
          </div>
          <ul className="fade-in">
            <li className="">
              <div className="d-flex">
                <img src={win1} className="me-3" alt ={win1}/> 
                <h5>Excellent Communication</h5>
              </div>
              <p className="ms-5">The single biggest problem in communication is<br/> the illusion that it has taken place.</p>
            </li>
            <li>
              <div className="d-flex">
                <img src={win1} className="me-3" alt="no data" />
                <h5>Extensive Market Knowledge</h5>
              </div>
              <p className="ms-5">In the world of real estate, knowledge is key. <br/>Without it, you are at the mercy of those who have it.</p>
            </li>
            <li>
              <div className="d-flex">
                <img src={win1} className="me-3"    alt=" no data"/>
                <h5>Strong Negotiators</h5>
              </div>
              <p className="ms-5">A successful negotiation is one where both parties <br/>feel they've achieved something valuable.</p>
            </li>
            <li>
              <button className="btn btn-outline-danger text-black mt-5 fade-in">Learn More</button>
            </li>
          </ul>
        </Col>
        <Col className="text-white col-lg-7 col-md-6 col-12 mt-5 mb-3">
          <div className="d-flex flex-column flex-md-row align-items-center fade-in">
            <div className="box-winning bg-danger  ">
              <img src={win} className="img-fluid" alt="Winning Fact"  />
            </div>
            <div>
              <img src={winning1} className="winningimg-1 ms-0 mb-3 ms-md-4 img-fluid" alt="Winning 1" />
            </div>
          </div>
          <img src={winning2} className="winningimg-2 img-fluid mt-md-0 fade-in" alt="Winning 2" />
        </Col>
      </Row>
    </Container>
  );
}

export default Winning;
