
// import { FaHeadphones } from "react-icons/fa";
// import { IoMdContact } from "react-icons/io";
// import "../Headerss/NavbarStyle.css"
// import Button from 'react-bootstrap/Button';
// import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import React, { useState } from 'react';

// import LoginModal from "../LoginModal"


// function Navbars() {
//   const [showLoginModal, setShowLoginModal] = useState(false);
//   // const [showRegister, setShowRegister] = useState(false);
//   // const handleOpenRegisters = () => setShowRegister(true);

//   const handleOpenLoginModal = () => setShowLoginModal(true);
//   const handleCloseLoginModal = () => setShowLoginModal(false);
//   // const handleCloseRegister = () => setShowRegister(false);
//   return (
//     <>
//       {/* {['sm'].map((expand) => (
//         <Navbar key={expand} expand={expand} className="bg-body-tertiary ">
//           <Container fluid>
//             <Navbar.Brand href="#" className="me-0">Chennai auction Propperty</Navbar.Brand>
//             <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
//             <Navbar.Offcanvas
//               id={`offcanvasNavbar-expand-${expand}`}
              
//               placement="end"
//             >
//               <Offcanvas.Header closeButton>
//                 <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                 
//                 </Offcanvas.Title>
//               </Offcanvas.Header>
//               <Offcanvas.Body>
//                 <Nav className="justify-content-end flex-grow-1 pe-3">
//                   <Nav.Link href="#action1">Appartments</Nav.Link>
//                   <Nav.Link href="#action2"> Individual Villa</Nav.Link>
//                   <div className="d-flex">

//                   <Nav.Link href="#action2">Land</Nav.Link>
//                   <span className="mt-2">/</span>
//                   <Nav.Link href="#action2"> Plots</Nav.Link>
//                   </div>
//                   <Nav.Link href=''>Blog</Nav.Link>
//                   <button className='btn btn-danger'>Post Your  Propperty</button>
//                   {/* <div className="d-flex"> */}

//                  {/* <Nav.Link href='' className="pe-0" onClick={handleOpenLoginModal} > Login</Nav.Link> */}
//                 {/* <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} /> */}
//                 {/* <span className="mt-2 ">/</span>   */}
//                 {/* <Nav.Link href='/Registers' className="ps-0"> Register</Nav.Link> */}
//                   {/* </div> */}
//                 {/* <Register show={showRegister} handleClose={handleCloseRegister} />   */}
//                 {/* onClick={handleOpenRegisters} */}
                
//                 {/* <Nav.Link href='/MyProfiles' className="pe-0" > Myprofile</Nav.Link> */}
//                 {/* </Nav> */}
//               {/* // </Offcanvas.Body> */}
//             {/* // </Navbar.Offcanvas> */}
//           {/* // </Container> */}
//         {/* // </Navbar> */}
//       {/* // ))} */} */}






//     </>
//   );
// }

// export default Navbars;
import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";

import "../Headerss/NavbarStyle.css"
import LoginComponent from "./LoginComponent";
function MenuBar() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const [showDropdown, setShowDropdown] = useState(false);

  const handleOffcanvasItemClick = () => {
    handleClose();
  };

  const navLinks = [
    {
      name: "Appartments",
      links: "/appartments",
    },
    {
      name: "Individual Villa",
      links: "/individual villa",
    },
    {
      name: "Land / Plots",
      links: "/land/plots",
    },
   
    
    {
      name: "Blog",
      links: "/blog",
    },
  ];

  return (
    <div className="sub-navbar d-flex ms-auto text-white ">

      <ul className="sub-navbar-menu d-flex  ">
        {navLinks.map((item, i) => (
          <li key={i} >
            <Link to={item.links} className="fs-6 fw-bold ms-3 text-white"> {item.name}  </Link>
            
          </li>
          
        ))}
        {/* <button className="btn btn-danger">Post your property</button>   */}
      </ul>

    
      <div className="offcanvas-sidebar">
        <IoMenu className="fs-2 ms-auto hambargure " onClick={handleShow} />

        <Offcanvas show={show} onHide={handleClose} backdrop="static" className="sasas">
          <Offcanvas.Header closeButton className="text-white">
            <Offcanvas.Title className="fw-bold ">
            Chennai auction Propperty
            </Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body className="">
            <ul className="offcanvas-sub-navbar-menu " >
              <li onClick={handleOffcanvasItemClick}>
                <Link to="/" className="text-decoration-none text-white">Appartments</Link>
              </li>
              <li onClick={handleOffcanvasItemClick}>
                <Link to="#" className="text-decoration-none text-white">Individual Villa</Link>
              </li>
              <li onClick={handleOffcanvasItemClick}>
               

                <Link to="#" className="text-decoration-none text-white">Land</Link>
                <span>/</span>
                <Link className="text-decoration-none text-white">Plots</Link>
                
              </li>
              <li onClick={handleOffcanvasItemClick}>
                <Link to="#" className="text-decoration-none text-white">Blog</Link>
              </li>
              {/* <li onClick={handleOffcanvasItemClick}>
                <Link to="#" className="btn btn-danger">Post your property</Link>
              </li> */}
              <li > <LoginComponent/></li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      {/* responsive header */}
    </div>
  );
}

export default MenuBar;
