import React from 'react';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Container from 'react-bootstrap/esm/Container';
import customer from "../Images/customer-image.png"
import { FaGreaterThan } from "react-icons/fa6";

import "../Style/customers.css"
import { FaLessThan } from "react-icons/fa6";

function Customers() {
    // const [value, setValue] = useState(0);

    return (
        <>  <div className='bg-danger'>

            <Container>
                <Row >
                    <Col className='col-md-6 col-sm-12 p-5 text-white'>
                        <div>
                            <ul>
                                <li>
                                    <h3>What our customers are saying about us?</h3>
                                </li>
                                <li>
                                    <p>Various versions have evolved over the years, sometimes by accident, sometimes on purpose injected humour and the like.</p>
                                </li>
                                <div className='d-flex '>

                                    <li >
                                        <h4>5M+</h4>
                                        <span>Happy People</span>
                                    </li>
                                    <li className='ms-4'>
                                        <h4>4.7/5</h4>
                                        <span>Overall rating </span>
                                        <span>    </span>
                                    </li>
                                </div>
                                <li>

                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col className='col-md-6 p-5 text-white'>
                        <div className=' box-customer p-2 '>
                            <div className='d-flex '>

                                <img src={customer} className='customer-img mt-3 ' alt='customer' />
                                <ul className='mt-3  p-2' >

                                <li>

                                    <h6 className='  text-white me-2'>
                                        Joy Elizabeth
                                    </h6>
                                    <p className='paragraph'>Designer</p>
                                    {/* <span className='ms-auto'>~~</span> */}
                                </li>

                            </ul>
                        </div>
                        <p>Our home-buying journey was smooth and enjoyable thanks to the excellent service and extensive listings. We found our dream home in no time!"</p>

                        <div >
                            <ul className='d-flex'>
                                <li className='backshadow'>  <FaLessThan className='ms-2' />
                                </li>
                                <li className='backshadow2'> <FaGreaterThan  className='ms-3'/>

                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </div >
        </>
    );
}

export default Customers;
