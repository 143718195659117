import "../Style/FooterStyle.css"
import React from 'react'

import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import Container from "react-bootstrap/Container";

function Footer() {
  return (
    <>
  
      <div className="bg-black text-white ">
        <Container>


          <div className=" footerss" >
           <div className="row">
            <div className="   col-lg-9 col-12">
            <div className="Cards">

              <p className="m-0">©2024. All Right Reserved. Design and Developed by <span className="text-danger">
                WEBDADS2U </span> PVT LTD</p>
            </div>

            </div>
            <div className=" col-lg-3 col-12 ">

            <div className="footers d-flex ms-auto ">

              <ul className="footer ">
                <li className="p-1">Follow Us</li>
                <li className="p-1"><FaFacebook /></li>
                <li className="p-1"><FaInstagram /></li>
                <li className="p-1"><FaLinkedin /></li>
              </ul>
            </div>
        
            </div>
           </div>
            
          </div>
        </Container>
      </div>


    </>
  )
}

export default Footer
