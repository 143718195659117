import React from 'react'
import About from '../Component/About'
function about() {
  return (
    <>
      <About/>
    </>
  )
}

export default about
