import "../Style/RegisterStyle.css"
import React from 'react'
import { useState } from "react";
// import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaMobileScreenButton } from "react-icons/fa6";
import { IoMdPerson } from "react-icons/io";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../utils";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
// import { Last } from "react-bootstrap/esm/PageItem";
import { IoIosMail } from "react-icons/io";
function Register() {

    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [Mobile, setMobile] = useState('+91');
    const [EMail, setEMail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmpassword, setconfirmPassword] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmpasswordShown, setconfirmPasswordShown] = useState(false);
    const navigate = useNavigate()

    const handleSubmit =  async  e => {
        e.preventDefault();

      await   axios.post(`${API_BASE_URL}/users/register`, {
            firstName: firstName,
            lastName: lastName,
            email: EMail,
            mobile: Mobile,
            password: password,
            confirmPassword: confirmpassword

        })
            .then((result) => {


                const userId = result.data.token;
                const refreshToken = result.data.refreshToken;
        
                localStorage.setItem("userId", userId);
                localStorage.setItem("refreshToken", refreshToken);
                console.log("login `response", result)
                Swal.fire({
                    text: " Register Successful ",
                    icon: "success"
                }
                );
                navigate("/LoginModal")
            })
            .catch((error) => {
                console.log("error", error)
            })
    };
    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
    };
    const toggleconfirmPasswordVisiblity = () => {

        setconfirmPasswordShown(!confirmpasswordShown);
    }



    return (
        <>
            <div className="poistion-relative Login-box">

                <form onSubmit={handleSubmit} className="box-model">
                    <div className='logo-img'>
                    </div>
                    <div><h5 className="text-center mt-2">Register</h5>
                        <p id="pop-Alert" className="text-center">Chennai Auction Property</p>
                    </div>
                    <div className="box-1" >
                        <input
                            type="text"
                            id="text"
                            value={firstName}
                            className="box-input "
                            placeholder=" FirstName"

                            onChange={(e) => setfirstName(e.target.value)}

                        />
                        <IoMdPerson />
                    </div>
                    <div className="box-1" >
                        <input
                            type="text"
                            id="text"
                            value={lastName}
                            className="box-input "
                            placeholder=" LastName"

                            onChange={(e) => setlastName(e.target.value)}

                        />
                        <IoMdPerson />
                    </div>
                    <div className="box-1" >
                        <input
                            type="tel"
                            id="Mobile"
                            value={Mobile}
                            className="box-input "
                            placeholder="Mobile Number"

                            onChange={(e) => setMobile(e.target.value)}
                            required
                        />
                        <FaMobileScreenButton />
                    </div>
                    <div className="box-1" >
                        <input
                            type="EMail"
                            id="EMail"
                            value={EMail}
                            className="box-input "
                            placeholder=' EMail'

                            onChange={(e) => setEMail(e.target.value)}
                            required
                        />
                        <IoIosMail className="text-indent" id="inputGroupPrepend3" />
                    </div>
                    <div className="box-1">

                        <input
                            type={passwordShown ? "text" : "password"}
                            id="password"
                            placeholder=" Password"
                            value={password}
                            className="box-input "

                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button onClick={togglePasswordVisiblity} id="btnicon">
                            {passwordShown ? (
                                <FaEye className="passwordbtn" />
                            ) : (
                                <FaEyeSlash className="passwordbtn" />
                            )}

                        </button>

                    </div>
                    <div className="box-1">

                        <input
                            type={confirmpasswordShown ? "text" : "password"}
                            id="confirm-password"
                            placeholder="Confirm Password"
                            value={confirmpassword}
                            className="box-input "

                            onChange={(e) => setconfirmPassword(e.target.value)}
                            required
                        />
                        <button onClick={toggleconfirmPasswordVisiblity} id="btnicon" className="">
                            {confirmpasswordShown ? (
                                <FaEye className="passwordbtn" />
                            ) : (
                                <FaEyeSlash className="passwordbtn" />
                            )}

                        </button>
                    </div>
                    <div>
                        <ul>

                    <li><input type="checkbox" className="mx-1" required />I agree to the Terms of Service and Privacy Policy.! </li>
                        </ul>
                    </div>

                    <div className="text-center">

                        <button className="btn btn-dark pt-1 mt-4 w-50" id="btn" disabled={false} >Singnup</button>
                    </div>
                    <div className="text-center mt-2"> Don't have an account?
                        <Link to="/Login " className="ms-2">Login</Link>
                    </div>
                </form>


            </div>

            <ToastContainer position="bottom-center"
                reverseOrder={false} />
        </>

    );


}

export default Register
