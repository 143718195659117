// import React, { useState, useEffect } from 'react';
// // import axios from 'axios';
// import EnquiryFormModal from '../Enquiry/Enquiry';
// import { Link, useLocation } from 'react-router-dom';

// function FilterContent() {
//   const [filteredProperties, setFilteredProperties] = useState([]);
//   // const [propertyCount, setPropertyCount] = useState(0);
//   const [expandedDescriptions, setExpandedDescriptions] = useState([]);
//   const [modalShow, setModalShow] = useState(false);

//   const location = useLocation();
//   const filteredPropertiesFromState = location.state?.filteredProperties || [];

//   useEffect(() => {
//     setFilteredProperties(filteredPropertiesFromState);
//     // setPropertyCount(filteredPropertiesFromState.length);
//     setExpandedDescriptions(new Array(filteredPropertiesFromState.length).fill(false));
//   }, [filteredPropertiesFromState]);

//   const toggleDescription = (index) => {
//     const updatedDescriptions = [...expandedDescriptions];
//     updatedDescriptions[index] = !updatedDescriptions[index];
//     setExpandedDescriptions(updatedDescriptions);
//   };

//   const formatPrice = (price) => {
//     return new Intl.NumberFormat('en-IN', {
//       style: 'currency',
//       currency: 'INR',
//     }).format(price);
//   };
  

//   return (
//     <div>
//       <div className="">
//         <div className="scrollable-container">
//           {filteredProperties.map((property, index) => (
//             <div className="card-container1 mt-5 mb-5" key={index}>
//               <div className="card-header1">
//                 <img
//                   src={property.propertyImages[0].propertyImage}
//                   alt="card-image"
//                   className="card-image1"
//                 />
//               </div>
//               <div className="card-body1 m-2">
//                 <h4 className="card-subtitle1">{property.propertyTitle}</h4>
//                 <h6 className="card-title1">
//                   <span className="fw-bold text-capitalize fs-6"></span>
//                   <i className="fa-solid fa-location-dot"></i> {property.address}
//                 </h6>

//                 <table className="table table-striped-columns border border-2">
//                   <thead>
//                     <tr className="bg-color-2">
//                       <th className="p-2 text-center fw-medium">Type</th>
//                       <th className="p-2 text-center fw-medium">Property Type</th>
//                       <th className="p-2 text-center fw-medium">Price</th>
//                       <th className="p-2 text-center fw-medium">Sqft</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr className="bg-color-1">
//                       <td className="p-2 text-center fw-light">{property.type}</td>
//                       <td className="p-2 text-center fw-light">{property.propertyType}</td>
//                       <td className="p-2 text-center fw-light">{formatPrice(property.price)}</td>
//                       <td className="p-2 text-center fw-light">{property.totalArea}</td>
//                     </tr>
//                   </tbody>
//                 </table>

//                 <div
//                   key={index}
//                   className={`card-text1 ${expandedDescriptions[index] ? 'expanded' : 'collapsed'}`}
//                   onClick={() => toggleDescription(index)}
//                   style={{ cursor: 'pointer' }}
//                 >
//                   <span className="fw-bold text-capitalize">{property.title}</span>
//                   <div
//                     dangerouslySetInnerHTML={{
//                       __html: expandedDescriptions[index]
//                         ? property.propertyDescription
//                         : `${property.propertyDescription.slice(0, 100)}...`,
//                     }}
//                   />
//                   <span style={{ color: 'blue', marginLeft: '0px' }} className="fw-bold text-black">
//                     {expandedDescriptions[index] ? 'Show Less' : 'Show More'}
//                   </span>
//                 </div>

//                 <div className="d-flex">
//                   <Link to={`/viewalldetails/${property._id}`} className="ms-auto">
//                     <button className="btn btn-warning opacity-75 fw-bold text-white">
//                       More Details
//                     </button>
//                   </Link>

//                   <button
//                     className="btn btn-outline-dark ms-2 fw-bold"
//                     onClick={() => setModalShow(true)}
//                   >
//                     Enquiry
//                   </button>

//                   <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default FilterContent;
import React, { useState, useEffect, useMemo } from 'react';
import EnquiryFormModal from '../Enquiry/Enquiry';
import { Link, useLocation } from 'react-router-dom';

function FilterContent() {
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const location = useLocation();
  const filteredPropertiesFromState = useMemo(() => location.state?.filteredProperties || [], [location.state]);

  useEffect(() => {
    setFilteredProperties(filteredPropertiesFromState);
    setExpandedDescriptions(new Array(filteredPropertiesFromState.length).fill(false));
  }, [filteredPropertiesFromState]);

  const toggleDescription = (index) => {
    const updatedDescriptions = [...expandedDescriptions];
    updatedDescriptions[index] = !updatedDescriptions[index];
    setExpandedDescriptions(updatedDescriptions);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(price);
  };

  return (
    <div>
      <div className="">
        <div className="scrollable-container">
          {filteredProperties.map((property, index) => (
            <div className="card-container1 mt-5 mb-5" key={index}>
              <div className="card-header1">
                <img
                  src={property.propertyImages[0].propertyImage}
                  alt="card-image"
                  className="card-image1"
                />
              </div>
              <div className="card-body1 m-2">
                <h4 className="card-subtitle1">{property.propertyTitle}</h4>
                <h6 className="card-title1">
                  <span className="fw-bold text-capitalize fs-6"></span>
                  <i className="fa-solid fa-location-dot"></i> {property.address}
                </h6>

                <table className="table table-striped-columns border border-2">
                  <thead>
                    <tr className="bg-color-2">
                      <th className="p-2 text-center fw-medium">Type</th>
                      <th className="p-2 text-center fw-medium">Property Type</th>
                      <th className="p-2 text-center fw-medium">Price</th>
                      <th className="p-2 text-center fw-medium">Sqft</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-color-1">
                      <td className="p-2 text-center fw-light">{property.type}</td>
                      <td className="p-2 text-center fw-light">{property.propertyType}</td>
                      <td className="p-2 text-center fw-light">{formatPrice(property.price)}</td>
                      <td className="p-2 text-center fw-light">{property.totalArea}</td>
                    </tr>
                  </tbody>
                </table>

                <div
                  key={index}
                  className={`card-text1 ${expandedDescriptions[index] ? 'expanded' : 'collapsed'}`}
                  onClick={() => toggleDescription(index)}
                  style={{ cursor: 'pointer' }}
                >
                  <span className="fw-bold text-capitalize">{property.title}</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: expandedDescriptions[index]
                        ? property.propertyDescription
                        : `${property.propertyDescription.slice(0, 100)}...`,
                    }}
                  />
                  <span style={{ color: 'blue', marginLeft: '0px' }} className="fw-bold text-black">
                    {expandedDescriptions[index] ? 'Show Less' : 'Show More'}
                  </span>
                </div>

                <div className="d-flex">
                  <Link to={`/viewalldetails/${property._id}`} className="ms-auto">
                    <button className="btn btn-warning opacity-75 fw-bold text-white">
                      More Details
                    </button>
                  </Link>

                  <button
                    className="btn btn-outline-dark ms-2 fw-bold"
                    onClick={() => setModalShow(true)}
                  >
                    Enquiry
                  </button>

                  <EnquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FilterContent;
