import "../Style/AboutStyle.css"
import React from 'react'
import Container from "react-bootstrap/esm/Container"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { FaLocationArrow } from "react-icons/fa";
import { Link } from "react-router-dom"


function About() {
    return (
        < >
            <section className="bg-black text-white  line   mt-2 ">
                <Container className=" mt-2" >
                    <Row>

                        <Col className="check text-center col-lg-12">

                            <h4 className="mt-3" >Subscribe Our Newsletter</h4>
                            <p>
                                We don’t send spam so don’t worry.
                            </p>




                        </Col>
                        <Row>

                            <Col className=" " lg={12}>

                               
                                    <form className="text-center">
                                        <div className="box-form bg-white m-auto    ">
                                            <input
                                                type="email"
                                                id="email"
                                                className="box-input-form csddasasasawervq "
                                                placeholder="Enter Your Email"
                                            />
                                            <button type="submit" className="btn btn-danger text-white form-button dggaatbat">Subscribe</button>
                                        </div>
                                    </form>
                              
                            </Col>
                        </Row>


                    </Row>
                    <Row className="bg-black text-white ">
                        <Col className="col-md-3 col-lg-3 col-sm-12 mt-5">

                            <ul>
                                <li>
                                    {/* <img src={logo} className="logo" /> */}
                                </li>
                                <li>Lorem ipsum dolor sit amet consectetur. Ut sit morbi ultricies tristique pharetra nulla. Feugiat id ullamcorper gravida commodo odio.
                                </li>
                            </ul>

                        </Col>
                        <Col className="col-md-3 col-lg-3 col-sm-3 mt-5 ">
                            <div className="mx-2">
                                <ul className="mx-2">
                                    <li>
                                        <h5>Popular Search</h5>

                                    </li>
                                    <li>
                                        Apartment for Sale
                                    </li>
                                    <li>
                                        Apartment for Rent
                                    </li>
                                    <li>
                                        Office for Sale
                                    </li>
                                    <li>
                                        Office for Rent
                                    </li>

                                </ul>
                            </div>
                        </Col>
                        <Col className="col-md-3 col-lg-3 col-sm-12 mt-5">
                            <ul className="mx-3">
                                <li>
                                    <h5 > Quick Links</h5>
                                </li>
                                <li>About</li>
                                <li >Blog</li>
                                <li>Contact</li>
                                <li >Terms of Condition</li>
                                <li>Privacy Policy</li>
                            </ul>

                        </Col>
                        <Col className=' col-lg-3 col-md-3'>



                            <div className="mt-5 ">
                                <ul className="">
                                    <li><h5 className="">ContactUs</h5></li>

                                </ul>
                                <div className="d-flex ms-4">
                                    <div>
                                        <BsFillTelephoneOutboundFill className="color " />
                                    </div>
                                    <div className="mx-2">

                                        <span>+91 - 9100000810</span>



                                    </div>
                                </div>
                                <div className="d-flex ms-4">
                                    <div className="">
                                        <FaLocationArrow className="color" />
                                    </div>
                                    <div className="mx-2">
                                        <Link>
                                            Location Here
                                        </Link>
                                    </div>
                                </div>




                            </div>
                        </Col>

                    </Row>
                </Container>

            </section>
        </>
    )
}


export default About
