
// import React, { useContext, useEffect, useState } from "react";
// import { Link, Navigate, useNavigate } from "react-router-dom";
// import { FiLogOut, FiEdit } from "react-icons/fi";
// import axios from "axios";
// import { API_BASE_URL } from "../utils";
// import EditProfileModal from "../Component/Updateprofile"; // Assuming you have a modal component for editing
// import "../Style/Profile.css";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form";
// import userContext from "../Component/UseContext";
// function MyProfile() {
//   const [user, setUser] = useState(null);
//   const [isEditMode, setIsEditMode] = useState(false);
//   const [editedUser, setEditedUser] = useState({});
//   const [isChangingPassword, setIsChangingPassword] = useState(false); // State for showing change password form
//   const [oldPassword, setOldPassword] = useState("");
//   const [newpassword, setNewPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [passwordError, setPasswordError] = useState("");
//   const { signOut } = useContext(userContext);
//   const navigate = useNavigate();
//   const refreshToken = localStorage.getItem("refreshToken");

//   useEffect(() => {
//     const accessToken = localStorage.getItem("accessToken");

//     if (!accessToken) {
//       navigate("/"); // Redirect to login page if no access token is found
//       return;
//     }

//     const headers = {
//       Authorization: `Bearer ${accessToken}`,
//       "Content-Type": "application/json",
//     };

//     axios
//       .get(`${API_BASE_URL}/users/myprofile`, { headers })
//       .then((response) => {
//         setUser(response.data.data);
//       })
//       .catch((error) => {
//         console.log("Error fetching user profile:", error);
//         if (error.response && error.response.status === 401) {
//           localStorage.removeItem("accessToken");
//           navigate("/");
//         }
//       });
//   }, [refreshToken]);

//   const   handleLogout = () => {
//     localStorage.removeItem("accessToken");
//     navigate("/");
   
//     // if(!localStorage.getItem("accessToken"))
//     // {
//     //   <Navigate to="/" replace />
//     // }

//     // alert("logut")
//   };

//     const handleEditClick = () => {
//       setIsEditMode(true);
//       setEditedUser({
//         firstName: user.firstName,
//         lastName: user.lastName,
//         email: user.email,
//         mobile: user.mobile,
//       });
//     };

//   const handleEditSubmit = () => {
//     const accessToken = localStorage.getItem("accessToken");

//     if (!accessToken) {
//       navigate("/");
//       return;
//     }

//     const headers = {
//       Authorization: `Bearer ${accessToken}`,
//       "Content-Type": "application/json",
//     };

//     axios
//       .put(`${API_BASE_URL}/users/updateprofile`, editedUser, { headers })
//       .then((response) => {
//         setUser(response.data.data);
//         setIsEditMode(false);
//       })
//       .catch((error) => {
//         console.log("Error updating user profile:", error);
//       });
//   };

//   const handleChangePasswordClick = () => {
//     setIsEditMode(false); // Ensure edit mode is off when changing password
//     setIsChangingPassword(true); // Show change password form
//   };

//   const handleChangePassword = (e) => {
//       e.preventDefault();

//       const accessToken = localStorage.getItem("accessToken");

//       if (!accessToken) {
//         navigate("/");
//         return;
//       }

//       if (newpassword !== confirmPassword) {
//         setPasswordError("Passwords do not match");
//         return;
//       }

//       const headers = {
//         Authorization: `Bearer ${accessToken}`,
//         "Content-Type": "application/json",
//       };

//       const passwordData = {
//         oldPassword,
//         newpassword,
//       };

//       axios
//         .patch(`${API_BASE_URL}/users/changepassword`, passwordData, { headers })
//         .then((response) => {
//           console.log("Password changed successfully:", response.data.data);
//           setIsChangingPassword(false); // Hide change password form
//           setOldPassword("");
//           setNewPassword("");
//           setConfirmPassword("");
//           setPasswordError("");
//           // Optionally: Show success message or update UI
//         })
//         .catch((error) => {
//           console.error("Error changing password:", error);
//           // Handle error: Display error message or handle specific errors
//         });
//     };

//   const handleEditCancel = () => {
//     setIsEditMode(false);
//     setEditedUser({});
//   };

//   return (
//     <div className="page-content page-container" id="page-content">
//       <Row>
//         <Col className="col-md-6 ps-0">
//           <div className="padding">
//             <div className="row container d-flex justify-content-center">
//               <div className="">
//                 <div className="card user-card-full">
//                   <div className="row m-l-0 m-r-0">
//                     <div className="col-sm-4 bg-c-lite-green user-profile">
//                       <div className="card-block text-center text-white">
//                         <div className="m-b-25">
//                           <img
//                             src="https://img.icons8.com/bubbles/100/000000/user.png"
//                             className="img-radius"
//                             alt="User-Profile-Image"
//                           />
//                         </div>
//                         {isEditMode ? (
//                           <EditProfileModal
//                             editedUser={editedUser}
//                             setEditedUser={setEditedUser}
//                             handleEditSubmit={handleEditSubmit}
//                             handleEditCancel={handleEditCancel}
//                           />
//                         ) : (
//                           <>
//                             <button
//                               className="btn btn-sm btn-outline-primary m-2"
//                               onClick={handleEditClick}
//                             >
//                               <FiEdit /> Edit Profile
//                             </button>
//                             <button
//                               className="btn btn-sm btn-outline-primary m-2"
//                               onClick={handleChangePasswordClick}
//                             >
//                               Change Password
//                             </button>
//                           </>
//                         )}
//                       </div>
//                     </div>
//                     <div className="col-sm-8">
//                       <div className="card-block">
//                         <h6 className="m-b-20 p-b-5 b-b-default f-w-600">
//                           Information
//                         </h6>
//                         <div className="row">
//                           <div className="col-sm-6">
//                             <p className="m-b-10 f-w-600">Name</p>
//                             <h6 className="text-muted f-w-400">
//                               {user
//                                 ? `${user.firstName} ${user.lastName}`
//                                 : "User"}
//                             </h6>
//                           </div>
//                           <div className="col-sm-6">
//                             <p className="m-b-10 f-w-600">Email</p>
//                             <h6 className="text-muted f-w-400">
//                               {user ? user.email : "Not available"}
//                             </h6>
//                           </div>
//                         </div>
//                         <div className="row">
//                           <div className="col-sm-6">
//                             <p className="m-b-10 f-w-600">Phone</p>
//                             <h6 className="text-muted f-w-400">
//                               {user ? user.mobile : "Not available"}
//                             </h6>
//                           </div>
//                         </div>
//                         <ul className="social-link list-unstyled m-t-40 m-b-10">
//                           <li>
//                             <a
//                               href="#!"
//                               data-toggle="tooltip"
//                               data-placement="bottom"
//                               title=""
//                               data-original-title="facebook"
//                               data-abc="true"
//                             >
//                               <i class="fa-brands fa-facebook"></i>
//                             </a>
//                           </li>
//                           <li>
//                             <a
//                               href="#!"
//                               data-toggle="tooltip"
//                               data-placement="bottom"
//                               title=""
//                               data-original-title="twitter"
//                               data-abc="true"
//                             >
//                               <i class="fa-brands fa-twitter"></i>
//                             </a>
//                           </li>
//                           <li>
//                             <a
//                               href="#!"
//                               data-toggle="tooltip"
//                               data-placement="bottom"
//                               title=""
//                               data-original-title="instagram"
//                               data-abc="true"
//                             >
//                               <i class="fa-brands fa-instagram"></i>                            </a>
//                           </li>
//                         </ul>
//                         <button
//                           className="btn btn-sm btn-outline-primary"
//                          onClick={signOut}
//                         >
//                           <FiLogOut className="mx-2 navigation-list-icon" />
//                           Log out
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </Col>

//           {/* Change Password Form */}
//           {isChangingPassword && (
//             <Modal show={isChangingPassword} onHide={() => setIsChangingPassword(false)}>
//               <Modal.Header closeButton>
//                 <Modal.Title>Change Password</Modal.Title>
//               </Modal.Header>
//               <Modal.Body>
//                 <Form onSubmit={handleChangePassword}>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Old Password</Form.Label>
//                     <Form.Control
//                       type="password"
//                       className="form-control"
//                       value={oldPassword}
//                       onChange={(e) => setOldPassword(e.target.value)}
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label>New Password</Form.Label>
//                     <Form.Control
//                       type="password"
//                       className="form-control"
//                       value={newpassword}
//                       onChange={(e) => setNewPassword(e.target.value)}
//                       required
//                     />
//                   </Form.Group>
//                   <Form.Group className="mb-3">
//                     <Form.Label>Confirm New Password</Form.Label>
//                     <Form.Control
//                       type="password"
//                       className="form-control"
//                       value={confirmPassword}
//                       onChange={(e) => setConfirmPassword(e.target.value)}
//                       required
//                     />
//                     {passwordError && (
//                       <p className="text-danger mt-2">{passwordError}</p>
//                     )}
//                   </Form.Group>
//                   <Button type="submit" className="btn btn-primary mr-2">
//                     Change Password
//                   </Button>
//                   <Button
//                     className="btn btn-secondary"
//                     onClick={() => setIsChangingPassword(false)}
//                   >
//                     Cancel
//                   </Button>
//                 </Form>
//               </Modal.Body>
//             </Modal>
//           )}
      
//       </Row>
//     </div>
//   );
// }

// export default MyProfile;
import React, { useContext, useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { FiLogOut, FiEdit } from "react-icons/fi";
import axios from "axios";
import { API_BASE_URL } from "../utils";
import EditProfileModal from "../Component/Updateprofile"; // Assuming you have a modal component for editing
import "../Style/Profile.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import userContext from "../Component/UseContext";


function MyProfile() {
  const [user, setUser] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedUser, setEditedUser] = useState({});
  const [isChangingPassword, setIsChangingPassword] = useState(false); // State for showing change password form
  const [oldPassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { signOut } = useContext(userContext);
  const navigate = useNavigate();
  const refreshToken = localStorage.getItem("refreshToken");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/"); // Redirect to login page if no access token is found
      return;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    axios
      .get(`${API_BASE_URL}/users/myprofile`, { headers })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        console.log("Error fetching user profile:", error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("accessToken");
          navigate("/");
        }
      });
  }, [refreshToken, navigate]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    signOut(); // Call signOut to update the context state
    navigate("/"); // Redirect to the login page
  };

  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedUser({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      mobile: user.mobile,
    });
  };

  const handleEditSubmit = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/");
      return;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    axios
      .put(`${API_BASE_URL}/users/updateprofile`, editedUser, { headers })
      .then((response) => {
        setUser(response.data.data);
        setIsEditMode(false);
      })
      .catch((error) => {
        console.log("Error updating user profile:", error);
      });
  };

  const handleChangePasswordClick = () => {
    setIsEditMode(false); // Ensure edit mode is off when changing password
    setIsChangingPassword(true); // Show change password form
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      navigate("/");
      return;
    }

    if (newpassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };

    const passwordData = {
      oldPassword,
      newpassword,
    };

    axios
      .patch(`${API_BASE_URL}/users/changepassword`, passwordData, { headers })
      .then((response) => {
        console.log("Password changed successfully:", response.data.data);
        setIsChangingPassword(false); // Hide change password form
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setPasswordError("");
        // Optionally: Show success message or update UI
      })
      .catch((error) => {
        console.error("Error changing password:", error);
        // Handle error: Display error message or handle specific errors
      });
  };

  const handleEditCancel = () => {
    setIsEditMode(false);
    setEditedUser({});
  };

  return (
    <div className="page-content page-container" id="page-content">
      <Row>
        <Col className="col-md-6 ps-0">
          <div className="padding">
            <div className="row container d-flex justify-content-center">
              <div className="">
                <div className="card user-card-full">
                  <div className="row m-l-0 m-r-0">
                    <div className="col-sm-4 bg-c-lite-green user-profile">
                      <div className="card-block text-center text-white">
                        <div className="m-b-25">
                          <img
                            src="https://img.icons8.com/bubbles/100/000000/user.png"
                            className="img-radius"
                            alt="User-Profile-Image"
                          />
                        </div>
                        {isEditMode ? (
                          <EditProfileModal
                            editedUser={editedUser}
                            setEditedUser={setEditedUser}
                            handleEditSubmit={handleEditSubmit}
                            handleEditCancel={handleEditCancel}
                          />
                        ) : (
                          <>
                            <button
                              className="btn btn-sm btn-outline-primary m-2"
                              onClick={handleEditClick}
                            >
                              <FiEdit /> Edit Profile
                            </button>
                            <button
                              className="btn btn-sm btn-outline-primary m-2"
                              onClick={handleChangePasswordClick}
                            >
                              Change Password
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="card-block">
                        <h6 className="m-b-20 p-b-5 b-b-default f-w-600">
                          Information
                        </h6>
                        <div className="row">
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600">Name</p>
                            <h6 className="text-muted f-w-400">
                              {user
                                ? `${user.firstName} ${user.lastName}`
                                : "User"}
                            </h6>
                          </div>
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600">Email</p>
                            <h6 className="text-muted f-w-400">
                              {user ? user.email : "Not available"}
                            </h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <p className="m-b-10 f-w-600">Phone</p>
                            <h6 className="text-muted f-w-400">
                              {user ? user.mobile : "Not available"}
                            </h6>
                          </div>
                        </div>
                        <ul className="social-link list-unstyled m-t-40 m-b-10">
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="facebook"
                              data-abc="true"
                            >
                              <i class="fa-brands fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="twitter"
                              data-abc="true"
                            >
                              <i class="fa-brands fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="instagram"
                              data-abc="true"
                            >
                              <i class="fa-brands fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                        <button
                          className="btn btn-sm btn-outline-primary"
                          onClick={handleLogout} // Use handleLogout function here
                        >
                          <FiLogOut className="mx-2 navigation-list-icon" />
                          Log out
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>

        {/* Change Password Form */}
        {isChangingPassword && (
          <Modal
            show={isChangingPassword}
            onHide={() => setIsChangingPassword(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleChangePassword}>
                <Form.Group controlId="oldPassword">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="newpassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={newpassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="confirmPassword">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                {passwordError && (
                  <p className="text-danger">{passwordError}</p>
                )}
                <Button variant="primary" type="submit">
                  Change Password
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </Row>
    </div>
  );
}

export default MyProfile;
