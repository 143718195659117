import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_BASE_URL } from '../utils';
// import axiosInstance from '../utils/429Error';

function ForgotPasswordModal({ show, handleClose }) {
  const [email, setEmail] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }

    setLoading(true);

    try {
      const response = await  axios.post(`${API_BASE_URL}/users/changepassword`, { email });
      console.log(response)
      Swal.fire({
        text: "Password reset link has been sent to your email",
        icon: "success",
      });
      handleClose();
    } catch (error) {
      console.error("Error in API call:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleForgotPassword}>
        <Modal.Body>
          <Form.Group controlId="formEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type="submit" disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send Reset Link'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default ForgotPasswordModal;
