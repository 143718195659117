
// import React, { useState, useEffect } from 'react';
// import { IoMdContact } from "react-icons/io";
// import { Link } from 'react-router-dom';
// import { RiCustomerService2Fill } from "react-icons/ri";
// import LoginModal from "../LoginModal";
// import MyProfile from '../MyProfiles';

// function LoginComponent() {
//   const [showModal, setShowModal] = useState(false);

//   const [showLoginModal, setShowLoginModal] = useState(false);




//   const handleOpenLoginModal = () => setShowLoginModal(true);
//   const handleCloseLoginModal = () => setShowLoginModal(false);
 
 

//   return (
//     <ul className="auth-an-cart-status-group auth-an-cart-status-group-rsponsive m-0 p-0">
//       {accessToken ? (
//         <>
//           <li className="auth-status ">
//             <Link to="/Myprofiles" className="login">
//               <IoMdContact className="navbar-icon"  />
//             </Link>
//           </li>
//           <li className="cart-status">
//             <Link to="#">
//               <RiCustomerService2Fill className="navbar-icon" />
//               <div className="badge"></div>
//             </Link>
//           </li>
//         </>
//       ) : (
//         <>
//           <li className="auth-status">
//             <Link to="#" onClick={handleOpenLoginModal} className="login text-decoration-none text-black fs-6">
//               Log In
//             </Link>
//             <span className='fs-6'>/</span>
//             <Link to="/Registers" className="login text-decoration-none text-black fs-6">
//               Register
//             </Link>
//           </li>
//         </>
//       )}
      
//       {/* Render LoginModal if showLoginModal state is true */}
//       <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
//     </ul>
//   );
// }

// export default LoginComponent;
import React, { useState, useContext } from 'react';
import { IoMdContact } from "react-icons/io";
import { Link } from 'react-router-dom';
import { RiCustomerService2Fill } from "react-icons/ri";
import LoginModal from "../LoginModal";
import userContext from '../UseContext';

function LoginComponent() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const {  signIn } = useContext(userContext);
  const accessToken = localStorage.getItem("accessToken")
  const handleOpenLoginModal = () =>{

   setShowLoginModal(true);
   signIn()
  }
  const handleCloseLoginModal = () => setShowLoginModal(false);

  return (
    <ul className="auth-an-cart-status-group auth-an-cart-status-group-rsponsive m-0 p-0 me-auto">
      {accessToken ? (
        <>
          <li className="auth-status ">
            <Link to="/Myprofiles" className="login">
              <IoMdContact className="navbar-icon fs-4" />
            </Link>
          </li>
          <li className="cart-status">
            <Link to="#">
              <RiCustomerService2Fill className="navbar-icon fs-4" />
              <div className="badge"></div>
            </Link>
          </li>
         
        </>
      ) : (
        <>
          <li className="auth-status " >
            <Link to="#" onClick={handleOpenLoginModal}  className="login text-decoration-none text-white fw-bold fs-6">
                Login   
            </Link>
            <span className='fs-6 m-2 fw-bold'>/</span>
            <Link to="/registers" className="login text-decoration-none fw-bold text-white fs-6">
              Register
            </Link>
          </li>
        </>
      )}
      <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
    </ul>
  );
}

export default LoginComponent;
