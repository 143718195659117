import React, {  useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
// import { CountryDropdown, CountryRegionData } from 'react-country-region-selector';
// import flagIndia from './flag-india.png'; // Replace with actual flag images for other countries
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const EnquiryFormModal = ({ show, onHide,_id }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');


    const [value, setValue] = useState("+91")
    //   const [selectedCountry, setSelectedCountry] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', { name, email });
        setName('');
        setEmail('');
        
        onHide();
    };
    // useEffect(()=>{
    //    axios.post(`${API_BASE_URL}/contact/${_id}`)
    //    .then(res=>{
    //     console.log(res.data)
    //    })
    // },[_id])

   

    return (
        <>


            <Modal show={show} onHide={onHide} size="md" centered>
                <Modal.Header closeButton>
                    <p className='m-auto'>

                    Contact with the Seller Right Now
                    </p>
                    <Modal.Title id="contained-modal-title-vcenter">
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formName">
                            {/* <Form.Label>Name</Form.Label> */}
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                                className='w-100'
                            />
                        </Form.Group>

                        <Form.Group controlId="formEmail">
                            {/* <Form.Label>Email address</Form.Label> */}
                            <Form.Control
                                type="email"
                                placeholder=" EMail "
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className='w-100 mt-4'
                            />
                        </Form.Group>

                    

                    
                        <div >
                            <PhoneInput
                                placeholder="Enter phone number"
                                value={value}
                                className='mt-4 form-control'
                                onChange={setValue} />
                            <input
                                type="checkbox"
                                // placeholder="  "
                                // value={email}
                                // onChange={(e) => setEmail(e.target.value)}
                                required
                                className='mt-4'
                            />
                            <label className='labels0'>I agree to be contacted by Chennaiproperties.in and others for similar properties or related services via phone, sms, e-mail, WhatsApp etc.</label>
                        </div>


                        <Button variant="danger" type="submit" className="w-100 rounded-0 mt-4 ">
                            Submit
                        </Button>
                        <label> By submitting I accept Chennaiproperties.in Terms and Conditions</label>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EnquiryFormModal;
