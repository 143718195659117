import React from 'react'
import Customers from '../Component/Customers'

const Customer = () => {


  return (
    <div>
      <Customers />

    </div>
  )
}

export default Customer
