
import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { API_BASE_URL } from "../utils";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authHeaders, setAuthHeaders] = useState(() => {
    const accessToken = localStorage.getItem("accessToken") !==null;
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }); 

  const refreshToken = async () => {
    const storedRefreshToken = localStorage.getItem("refreshToken");
    console.log("Stored Refresh Token:", storedRefreshToken); // Debugging log  
    
    const headers = {
      Authorization: `Bearer ${storedRefreshToken}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(
        `${API_BASE_URL}/users/refresh`,
        { refreshToken: storedRefreshToken },
        { headers }
      )
      
      const newAccessToken = response.data;
      console.log("response in refreshToken api", response.data);
    
      return newAccessToken;
    } 
    
    catch (error) {
      console.error("Error refreshing access token:", error);
      throw error;
    }
  };

  useEffect(() => {
    const refreshAccessToken = async () => {
      try {
        const newAccessToken = await refreshToken();
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ NewAccesss-Token", newAccessToken);
        
        const accessToken = newAccessToken.data.accessToken;
        const newRefreshToken = newAccessToken.data.refreshToken;

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", newRefreshToken);

        setAuthHeaders({
          Authorization: `Bearer ${accessToken}`,
        });
      } catch (error) {
        console.error("Error refreshing access token:", error);
      }
    };

    const checkTokenInterval = setInterval(refreshAccessToken, 360000); // 30 minutes

    return () => clearInterval(checkTokenInterval);
  }, []);

  return (
    <AuthContext.Provider value={authHeaders}>{children}</AuthContext.Provider>
  );
};

export const useAuthHeaders = () => {
  return useContext(AuthContext);
};







