import React from 'react'

import Heroimage from '../Component/Heroimage'
import PriceRange from '../Component/PriceRange'
// import About from '../Component/About'
import Customer from './Customer'
import Winning from '../Component/Winning'
import FindProperties from '../Component/FindProperties'
import Discover from '../Component/ExploresAll/Discover'
import Featured from '../Component/Featured'
import Explore from '../Component/ExploresAll/Explore'
import Login from "../Component/LoginModal"
// import Registers from '../Component/Registers'

function Home() {
  return (
    <div>
      
      <Heroimage/>
      
      <Login/>
      <PriceRange path="/pricerange/._id"/>
      <Explore/>
      
      <Featured/>
      <Discover/>
      <FindProperties/>
      <Winning/>
      <Customer/>

    
    </div>
  )
}

export default Home
