// import React from "react";
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";

// const EditProfileModal = ({
//   editedUser,
//   setEditedUser,
//   handleEditSubmit,
//   handleEditCancel,
// }) => {
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setEditedUser((prev) => ({
//       ...prev,
//       [name]: value,
//     }));
//   };

//   return (
//     <Modal show={true} onHide={handleEditCancel}>
//       <Modal.Header closeButton>
//         <Modal.Title>Edit Profile</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <form>
//           <div className="mb-3">
//             <label className="form-label">First Name</label>
//             <input
//               type="text"
//               className="form-control"
//               name="firstName"
//               value={editedUser.firstName || ""}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="mb-3">
//             <label className="form-label">Last Name</label>
//             <input
//               type="text"
//               className="form-control"
//               name="lastName"
//               value={editedUser.lastName || ""}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="mb-3">
//             <label className="form-label">Email</label>
//             <input
//               type="email"
//               className="form-control"
//               name="email"
//               value={editedUser.email || ""}
//               onChange={handleChange}
//             />
//           </div>
//           <div className="mb-3">
//             <label className="form-label">Phone</label>
//             <input
//               type="tel"
//               className="form-control"
//               name="mobile"
//               value={editedUser.mobile || ""}
//               onChange={handleChange}
//             />
//           </div>
//         </form>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleEditCancel}>
//           Cancel
//         </Button>
//         <Button variant="primary" onClick={handleEditSubmit}>
//           Save Changes
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default EditProfileModal;
import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const EditProfileModal = ({
  editedUser,
  setEditedUser,
  handleEditSubmit,
  handleEditCancel,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal show={true} onHide={handleEditCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={editedUser.firstName || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={editedUser.lastName || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={editedUser.email || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <Form.Control
              type="tel"
              name="mobile"
              value={editedUser.mobile || ""}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleEditCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleEditSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
    
  );
};

export default EditProfileModal;
