import React from "react";
import { Link } from "react-router-dom";
import {Navbar }from "react-bootstrap";
import LoginComponent from "./LoginComponent";
import Menubar from "../Headerss/Menubar"

function MainHeader() {
  return (
    <> 
<div className="">

    <Navbar className="navbar  " style={{padding:"0px 16px"}}>
  


    <Link to="/" className="text-decoration-none text-white fs-4 ">
        {/* <img src={Logo} className="brand-logo" /> */}
      Chennai Auction Property
      </Link>
            <Menubar/>
      <LoginComponent/>
    

    </Navbar>
</div>
    </>
  );
}

export default MainHeader;